import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import axios from "../../axios";
import PageLoading from "../../components/pages/Loading";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import SectionPagination from "../../components/sections/Pagination";
import LayoutModal from "../../components/layouts/Modal";
import TableNew, {
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../../components/layouts/TableNew";
import {
  FilterCabang,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PenilaianKompetensiHasil = ({ user }) => {

  const history = useHistory();  

  if (user.jabatan !== "Bagian SDM" && user.jabatan !== "IT Programmer") {
    history.push("/dashboard");
  }

  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");

  const [pegawai, setPegawai] = useState([]);
  const [pegawaiFilter, setPegawaiFilter] = useState([]);
  const [pegawaiPenilaian, setPegawaiPenilaian] = useState([]);
  const [soalPenilaian, setSoalPenilaian] = useState([]);
  const [jabatan, setJabatan] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`/api/penilaian-kompetensi-hasil?page=${page}&pagination=${pagination}&search=${search}&cabang=${cabang}`,
        { headers: { Authorization: "Bearer " + token } }
      );

      // console.log(res.data);
      if (res.data && res.data.pegawai) {
        if (search === "") {
          setPegawai(res.data.pegawai);
        }
        setPegawaiFilter(res.data.pegawai)
        setPegawaiPenilaian(res.data.pegawai_penilaian);
        setSoalPenilaian(res.data.soal_penilaian);
        setJabatan(res.data.jabatan);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, setIsLoaded, page, pagination, search, cabang]);

  useEffect(() => {
    setTitle("Hasil Penilaian Kompetensi");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="row mb-3">
          <div className="col-lg-4">
            <FilterCabang
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-lg-4">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-lg-4">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>

        <TableNew>
          <Thead>
            <Tr className="text-center">
              <Th>No</Th>
              <Th>NIP</Th>
              <Th>Nama</Th>
              <Th>
                <i className="fa fa-fw fa-cog"></i>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {pegawaiFilter.length > 0 &&
              pegawaiFilter.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>{i.nip}</Td>
                    <Td>{i.nama}</Td>
                    <Td className="text-center">
                      <Detail
                        pegawai={i}
                        allPegawai={pegawai}
                        pegawaiPenilaian={pegawaiPenilaian}
                        soalPenilaian={soalPenilaian}
                        jabatan={jabatan}
                      />
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </TableNew>
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const Detail = ({
  pegawai,
  allPegawai,
  pegawaiPenilaian,
  soalPenilaian,
  jabatan,
}) => {
  const [modal, setModal] = useState(false);

  // const [penilaiId, setPenilaiId] = useState([]);
  // const [penilai, setPenilai] = useState([]);

  // const handleOpen = () => {
  //   if (pegawai.penilai && pegawai.penilai.length > 0) {
  //     let allPenilaiId = pegawai.penilai.map((p) => p.penilai_id);

  //     let removePenilaiId = allPenilaiId.filter((api, i) => {
  //       if (allPenilaiId.indexOf(api) === i) {
  //         return 1;
  //       }
  //       return 0;
  //     });

  //     let allPenilai = pegawai.penilai.map((p) => p);

  //     setPenilai(allPenilai);
  //     setPenilaiId(removePenilaiId);
  //   }

  //   setModal(true);
  // };

  // const handleClose = () => {
  //   setModal(false);
  // };

  const [penilai, setPenilai] = useState([]);

  const handleOpen = () => {
    const duplicateId = pegawaiPenilaian
      .filter((pp) => parseInt(pp.dinilai_id) === parseInt(pegawai.id))
      .map((pp) => parseInt(pp.penilai_id));

    const uniqueId = [...new Set(duplicateId)];

    const pegawaiFiltered = allPegawai.filter((p) =>
      uniqueId.includes(parseInt(p.id))
    );

    setPenilai(pegawaiFiltered);

    console.log(pegawai);

    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary-3 text-sm py-0 px-3"
        onClick={handleOpen}
        type="button"
        title="Lihat Detail Penilaian"
      >
        <i className="fa fa-fw fa-sm fa-square-poll-vertical" /> Hasil Penilaian
      </button>
      <LayoutModal
        modal={modal}
        title={`Hasil Penilaian Pegawai a.n ${pegawai.nama}`}
        onClose={handleClose}
      >
        {pegawai.pegawai_jabatan.length > 0 &&
          pegawai.pegawai_jabatan.map((pjDinilai) => {
            return (
              <div key={pjDinilai.id}>
                <h3>
                  Dinilai Sebagai{" "}
                  {jabatan
                    .filter(
                      (j) => parseInt(j.id) === parseInt(pjDinilai.jabatan_id)
                    )
                    .map((j) => j.nama)}
                </h3>
                <TableNew>
                  <Thead>
                    <Tr>
                      <Th className="text-center" rowSpan={2}>No</Th>
                      <Th className="text-center" rowSpan={2}>NIP</Th>
                      <Th className="text-center" rowSpan={2}>Nama</Th>
                      <Th className="text-center" rowSpan={2}>Jabatan</Th>
                      <Th className="text-center" colSpan={soalPenilaian.length}>Penilaian</Th>
                    </Tr>
                    <Tr>
                      {soalPenilaian.map((sp) => {
                        return (
                          <Td key={sp.id} className="text-nowrap text-center">
                            {sp.judul}
                          </Td>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {penilai.map((p, index) => {
                      return (
                        <Tr key={index}>
                          <Td className="text-center">{index + 1}</Td>
                          <Td>{p.nip}</Td>
                          <Td className="text-nowrap">{p.nama}</Td>
                          <Td>
                            {p.pegawai_jabatan.length > 0 &&
                              p.pegawai_jabatan.map((pj, index) => {
                                return (
                                  <div className="row" key={pj.id}>
                                    <div className="col-1 text-center">
                                      {index + 1}.
                                    </div>
                                    <div className="col-11 text-nowrap">
                                      {jabatan
                                        .filter(
                                          (j) =>
                                            parseInt(j.id) ===
                                            parseInt(pj.jabatan_id)
                                        )
                                        .map((j) => j.nama)}
                                    </div>
                                  </div>
                                );
                              })}
                          </Td>
                          {soalPenilaian.map((sp) => {
                            return (
                              <Td key={sp.id}>
                                {p.pegawai_jabatan.length > 0 &&
                                  p.pegawai_jabatan.map((pj) => {
                                    return (
                                      <div className="row" key={pj.id}>
                                        <div className="col-12 text-nowrap text-center">
                                          {pegawaiPenilaian.filter(
                                            (pp) =>
                                              parseInt(pp.penilai_id) ===
                                                parseInt(p.id) &&
                                              parseInt(
                                                pp.penilai_jabatan_id
                                              ) === parseInt(pj.jabatan_id) &&
                                              parseInt(pp.dinilai_id) ===
                                                parseInt(pegawai.id) &&
                                              parseInt(
                                                pp.dinilai_jabatan_id
                                              ) ===
                                                parseInt(
                                                  pjDinilai.jabatan_id
                                                ) &&
                                              parseInt(pp.soal_id) ===
                                                parseInt(sp.id)
                                          ).length > 0
                                            ? pegawaiPenilaian
                                                .filter(
                                                  (pp) =>
                                                    parseInt(pp.penilai_id) ===
                                                      parseInt(p.id) &&
                                                    parseInt(
                                                      pp.penilai_jabatan_id
                                                    ) ===
                                                      parseInt(pj.jabatan_id) &&
                                                    parseInt(pp.dinilai_id) ===
                                                      parseInt(pegawai.id) &&
                                                    parseInt(
                                                      pp.dinilai_jabatan_id
                                                    ) ===
                                                      parseInt(
                                                        pjDinilai.jabatan_id
                                                      ) &&
                                                    parseInt(pp.soal_id) ===
                                                      parseInt(sp.id)
                                                )
                                                .map((pp) => pp.value)
                                            : "-"}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
            );
          })}
      </LayoutModal>
    </>
  );
};

export default PenilaianKompetensiHasil;