import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchPegawai } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import PageLoading from "../../components/pages/Loading";
import { titleState } from "../../storage/pages";
import axios from "../../axios";
import { saToast } from "../../helpers";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const PenilaianKompetensiStruktur = ({ user }) => {

  const history = useHistory();  

  if (user.jabatan !== "Bagian SDM" && user.jabatan !== "IT Programmer") {
    history.push("/dashboard");
  }

  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultView, setDefaultView] = useState(true);
  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPegawai("all");
      setItems(res.data.pegawai);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    setTitle("Struktur Penilaian");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="text-gray-900 mb-3">
          <h3>Note :</h3>
          <li>
            Pengecekan dilakukan oleh system dengan cara login ke semua akun.
          </li>
          <li>
            Pengecekan setiap akun membutuhkan waktu 2 s/d 4 detik.
          </li>
          <li>
            Proses pada halaman ini lumayan berat sehingga dapat membuat server bekerja lebih banyak yang akan berdampak terhadap performa server.
          </li>
        </div>
        {/* <div className="d-flex flex-wrap mb-2">
            <button onClick={() => setDefaultView(true)} className="btn btn-primary-2 mx-1 mb-2"><i className="fa fa-fw fa-list"/> Tampilan Struktur</button>
            <button onClick={() => setDefaultView(false)} className="btn btn-primary-2 mx-1 mb-2"><i className="fa fa-fw fa-table"/> Tampilan Tabel</button>
        </div> */}
        {defaultView &&
          <div className="overflow-y-scroll overflow-x-auto border rounded p-3" style={{ height: "calc(100vh - 200px)" }}>
            {items &&
              items.length > 0 &&
              items.map((i, index) => {
                return (
                  <div className="row" style={{ minWidth: "75rem" }} key={index}>
                    <div className="col-6 pb-3 pe-0">
                      <div style={{ position: "sticky", top: 20, zIndex: 1 }}>
                        <div className="p-3 bg-silver-2 rounded me-4">
                          <div className="d-flex justify-content-between align-items-start">
                            <p className="">No. {index + 1}</p>
                            <p className="mb-0 bg-warning-2 rounded fw-medium px-2">{i.grade ? `Grade ${i.grade}` : "Belum ditentukan"}</p>
                          </div>
                          <p className="mb-0">Nama :</p>
                          <h5 className="mb-0 fw-medium">{i.nama}</h5>
                          <p className="mb-0"><span className="fw-medium">({i.nip})</span> Group : <span className="fw-medium text-primary">{i.group ? i.group : "Belum ditentukan"}</span></p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="px-5 py-3 d-inline-block bg-white border-start border-bottom border-silver">
                            <div className="px-4" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 ps-0 pt-5">
                      <div className="mt-5">
                        <div className="p-3 bg-silver-2 rounded">
                          <table className="mb-0 table table-sm">
                            <thead>
                              <tr>
                                <th className="text-center fw-semibold">No</th>
                                <th className="text-start fw-semibold">Nama</th>
                                <th className="text-center fw-semibold">Cabang</th>
                                <th className="text-center fw-semibold">Group</th>
                                <th className="text-center fw-semibold">Grade</th>
                              </tr>
                            </thead>
                            <tbody>
                              {i.group !== null && i.grade !== null ? (
                                <GetPenilai defaultView={defaultView} pegawai={i} setTime={7000 * (index + 1)} />
                              ) : (
                                <tr>
                                  <td colSpan={5} className="text-gray-900 text-nowrap"><i className="fa fa-fw fa-circle-info" /> Belum ditentukan</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        }

        {!defaultView &&
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>NIP</Th>
                <Th>Nama</Th>
                <Th>Group</Th>
                <Th>Grade</Th>
                <Th>Nama Dinilai</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items &&
                items.length > 0 &&
                items.map((i, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-center">{index + 1}</Td>
                      <Td className="text-nowrap">{i.nip}</Td>
                      <Td className="text-nowrap">{i.nama}</Td>
                      <Td className="text-nowrap text-center">
                        {i.group ?? "Belum ditentukan"}
                      </Td>
                      <Td className="text-nowrap text-center">
                        {i.grade ?? "Belum ditentukan"}
                      </Td>
                      {i.group !== null && i.grade !== null ? (
                        <Td className="text-nowrap">
                          <GetPenilai defaultView={defaultView} pegawai={i} setTime={7000 * (index + 1)} />
                        </Td>
                      ) : (
                        <Td className="text-nowrap">Belum ditentukan</Td>
                      )}
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        }
      </>
    );
  } else {
    return (
      <PageLoading title="Proses ini hanya bisa dilakukan di local server karna memungkinkan padatnya trafic" />
    );
  }
};

const GetPenilai = (props) => {
  const { pegawai, setTime, defaultView } = props;
  const [styleClass, setStyleClass] = useState("");

  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        `/api/penilaian-kompetensi?pegawai_id=${pegawai.id}`
      );
      saToast("success", `Check akun milik ${pegawai.nama}`);
      setItems(res.data.pegawai);
      if (res.data.max) {
        setStyleClass("text-danger");
      } else {
        setStyleClass("text-success");
      }
    } catch (err) {
      console.log(err);
    }
  }, [pegawai.id, pegawai.nama]);

  useEffect(() => {
    let tO = setTimeout(() => {
      fetchData();
    }, setTime);
    return () => {
      clearTimeout(tO);
    };
  }, [fetchData, setTime]);

  return (
    <>
      {defaultView ?
        items.length > 0 ?
          items.map((i, index) => {
            return (
              <React.Fragment key={index}>
                <tr className="text-nowrap">
                  <td className="text-center">{index + 1}</td>
                  <td className="text-start">{i.nama}</td>
                  <td className="text-center">{i.cabang}</td>
                  <td className="text-center">{i.group}</td>
                  <td className="text-center">{i.grade}</td>
                  {i.pegawai_penilaian && i.pegawai_penilaian.length > 0 && (
                    <td className={styleClass}>
                      {i.pegawai_penilaian.map((i, index) => {
                        return (
                          i.penilai_id === pegawai.id && (
                            <p key={index}>
                              {i.soal_id} = {i.value}
                            </p>
                          )
                        );
                      })}
                    </td>
                  )}
                </tr>
              </React.Fragment>
            );
          })
          :
          <tr>
            <td colSpan={5} className="text-nowrap"><i className="fa fa-fw fa-spinner fa-spin" /> Mengecek data...</td>
          </tr>
        :
        items.length > 0 ? (
          <ol>
            {items.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  <li>
                    {i.nama}
                    {`  ||  `}
                    <span className="text-primary">{i.cabang}</span>
                    {`  ||  `}
                    <span className="text-success">{i.group}</span>
                    {`  ||  `}
                    <span className="text-info">Level {i.grade}</span>
                    {i.pegawai_penilaian && i.pegawai_penilaian.length > 0 && (
                      <ol className={styleClass}>
                        {i.pegawai_penilaian.map((i, index) => {
                          return (
                            i.penilai_id === pegawai.id && (
                              <React.Fragment key={index}>
                                <li>
                                  {i.soal_id} = {i.value}
                                </li>
                              </React.Fragment>
                            )
                          );
                        })}
                      </ol>
                    )}
                  </li>
                </React.Fragment>
              );
            })}
          </ol>
        ) : (
          <span><i className="fa fa-fw fa-spinner fa-spin"/> Mengecek data...</span>
        )
      }
    </>
  )
};

export default PenilaianKompetensiStruktur;
