import React, { useEffect, useState, useCallback, createContext } from "react";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { Switch, Route } from "react-router-dom";
import { fetchCeklistMonitoring } from "./apis";
import CeklistMonitoring from "./pages/CeklistMonitoring";
import Create from "./pages/Create";
import Edit from "./pages/Edit";
import List from "./pages/List";
import Auditor from "./pages/Auditor";
import { fetchCabang } from "../cabang/apis";
import { fetchDepartemen } from "../departemen/apis";
import { fetchPegawai } from "../../api";
import { fetchCeklistMonitoringList } from "../ceklist-monitoring-list/apis";
import AuditorList from "./pages/AuditorList";
import Report from "./pages/Report";
import Auditee from "./pages/Auditee";
import Laporan from "./pages/Laporan";
import DashboardMain from "../../components/dashboard/Main";

export const CeklistMonitoringContext = createContext();

const CeklistMonitoringRoutes = () => {
  const setTitle = useSetRecoilState(titleState);
  const mainUrl = "/ceklist-monitoring";

  const [page, setPage] = useState(1);
  const [type, setType] = useState("");
  const [filterCabang, setFilterCabang] = useState("");

  const [ceklistMonitoring, setCeklistMonitoring] = useState({});
  const [cabang, setCabang] = useState([]);
  const [departemen, setDepartemen] = useState([]);
  const [auditor, setAuditor] = useState([]);
  const [ceklistMonitoringList, setCeklistMonitoringList] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  const fetchData = useCallback(
    async (
      { auditor_id = "", auditee_id = "", pageCustom = false } = false
    ) => {
      setIsLoaded(false);
      try {
        const res = await fetchCeklistMonitoring(
          pageCustom || page,
          `auditor_id=${auditor_id}&auditee_id=${auditee_id}&type=${type}&cabang=${filterCabang}`
        );
        if (res.data && res.data.ceklist_monitoring) {
          setCeklistMonitoring(res.data.ceklist_monitoring);
          setTitle(res.data.title);
          setIsLoaded(true);
        }
      } catch (err) {
        console.error(err.response);
      }
    },
    [setTitle, page, type, filterCabang]
  );

  useEffect(() => {
    const fetchDataCabang = async () => {
      const res = await fetchCabang("all");
      setCabang(res.data.cabang);
    };

    fetchDataCabang();

    const fetchDataDepartemen = async () => {
      const res = await fetchDepartemen("all");
      setDepartemen(res.data.departemen);
    };

    fetchDataDepartemen();

    const fetchDataAuditor = async () => {
      const res = await fetchPegawai("all");
      setAuditor(res.data.pegawai);
    };

    fetchDataAuditor();

    const fetchDataCeklistMonitoringList = async () => {
      const res = await fetchCeklistMonitoringList("all");
      setCeklistMonitoringList(res.data.ceklist_monitoring_list);
    };

    fetchDataCeklistMonitoringList();
  }, []);

  return (
    <CeklistMonitoringContext.Provider
      value={{
        ceklistMonitoring,
        setPage,
        setType,
        setFilterCabang,
        cabang,
        departemen,
        auditor,
        ceklistMonitoringList,
        isLoaded,
        setIsLoaded,
      }}
    >
      <Switch>
        <Route exact path={mainUrl}>
          <DashboardMain>
            <CeklistMonitoring mainUrl={mainUrl} onFetchData={fetchData} />
          </DashboardMain>
        </Route>
        <Route path={`${mainUrl}/create`}>
          <DashboardMain>
            <Create mainUrl={mainUrl} onFetchData={fetchData} />
          </DashboardMain>
        </Route>
        <Route path={`${mainUrl}/:id/edit`}>
          <DashboardMain>
            <Edit mainUrl={mainUrl} onFetchData={fetchData} />
          </DashboardMain>
        </Route>
        <Route path={`${mainUrl}/:id/list`}>
          <DashboardMain>
            <List mainUrl={mainUrl} onFetchData={fetchData} />
          </DashboardMain>
        </Route>
        <Route path={`${mainUrl}/:id/report`}>
          <DashboardMain>
            <Report mainUrl={mainUrl} onFetchData={fetchData} />
          </DashboardMain>
        </Route>
        <Route path={`${mainUrl}/:id/laporan`}>
          <Laporan mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        {/* Auditor */}
        <Route exact path={`${mainUrl}/auditor`}>
          <DashboardMain>
            <Auditor mainUrl={mainUrl} onFetchData={fetchData} />
          </DashboardMain>
        </Route>
        <Route path={`${mainUrl}/auditor/:id`}>
          <DashboardMain>
            <AuditorList mainUrl={mainUrl} onFetchData={fetchData} />
          </DashboardMain>
        </Route>
        {/* End Audito */}
        <Route exact path={`${mainUrl}/auditee`}>
          <DashboardMain>
            <Auditee mainUrl={mainUrl} onFetchData={fetchData} />
          </DashboardMain>
        </Route>
      </Switch>
    </CeklistMonitoringContext.Provider>
  );
};

export default CeklistMonitoringRoutes;
