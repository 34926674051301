import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterSearch,
} from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import { saAlert, saConfirm } from "../../helpers";
import { deleteLembur, fetchLembur } from "../../api";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const LemburMaster = () => {
  const user = useRecoilValue(userState);

  const history = useHistory();  

  if (user.jabatan !== "Bagian SDM" && user.jabatan !== "IT Programmer") {
    history.push("/dashboard");
  }

  const setTitle = useSetRecoilState(titleState);

  useEffect(() => setTitle("Data Lembur Master"), [setTitle]);

  const [lembur, setLembur] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchLembur(
        page,
        `search=${search}&cabang=${cabang}&is_master=true`
      );
      if (res.data && res.data.lembur) {
        setLembur(res.data.lembur);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, search, cabang]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = async (id) => {
    try {
      const res = await deleteLembur(id);
      if (res.data === "success") {
        fetchData();
        saAlert("success", "Berhasil hapus data lembur !");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {user.jabatan === "IT Programmer" ? (
        <>
          <div className="row mb-3">
            <div className="col-6">
              <FilterCabang pusat={true} onChange={(res) => setCabang(res)} />
            </div>
            <div className="col-6">
              <FilterSearch onChange={(res) => setSearch(res)} />
            </div>
          </div>

          <div className="row">
            <div className="col-5 pe-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th>No</Th>
                    <Th>Cabang</Th>
                    <Th>Request</Th>
                    <Th>Approve</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lembur.data &&
                    lembur.data.length > 0 &&
                    lembur.data.map((i, index) => {
                      return (
                        <Tr key={index}>
                          <Td className="text-center">{index + lembur.from}</Td>
                          <Td className="text-nowrap">
                            {i.pegawai && i.pegawai.cabang}
                          </Td>
                          <Td className="text-nowrap">
                            {i.pegawai && i.pegawai.nama}
                          </Td>
                          <Td className="text-nowrap">
                            {i.atasan && i.atasan.nama}
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-6 px-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th>Dari</Th>
                    <Th>Sampai</Th>
                    <Th>Lama</Th>
                    <Th>Pekerjaan</Th>
                    <Th>Keterangan</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lembur.data &&
                    lembur.data.length > 0 &&
                    lembur.data.map((i, index) => {
                      return (
                        <Tr key={index}>
                          <Td className="text-nowrap">{i.dari}</Td>
                          <Td className="text-nowrap">{i.sampai}</Td>
                          <Td className="text-nowrap">{i.lama}</Td>
                          <Td className="text-nowrap">{i.pekerjaan}</Td>
                          <Td className="text-nowrap">{i.keterangan}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-1 ps-0">
              <TableNew copy={false}>
                <Thead>
                  <Tr className='text-center'>
                    <Th>
                      <i className="fa fa-bars"></i>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {lembur.data &&
                    lembur.data.length > 0 &&
                    lembur.data.map((i, index) => {
                      return (
                        <Tr key={index}>
                          <Td className="text-nowrap text-center">
                            <SmallButton 
                              type='delete' 
                              onClick={() => {
                                saConfirm(
                                  "warning",
                                  "Yakin hapus data lembur ?"
                                ).then((res) => {
                                  if (res.isConfirmed) {
                                    handleDelete(i.id);
                                  }
                                });
                              }}/>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </TableNew>
            </div>
          </div>

          {lembur.links && lembur.links.length > 0 && (
            <SectionPagination
              links={lembur.links}
              onChangePage={(res) => setPage(res)}
            />
          )}
        </>
      ) : (
        "No Data"
      )}
    </>
  );
};

export default LemburMaster;
