import React, { useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { LayoutTable, LayoutThead } from "../../../components/layouts/Table";
import { saAlert } from "../../../helpers";
import { patchCeklistMonitoring } from "../apis";
import { CeklistMonitoringContext } from "../Routes";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../../components/layouts/TableNew";
import SmallButton from "../../../components/forms/SmallButton";
import { NewSubmitButton } from "../../../components/layouts/Modal";

const List = ({ mainUrl, onFetchData }) => {
  const { id } = useParams();
  const {
    ceklistMonitoring,
    cabang,
    departemen,
    auditor,
    ceklistMonitoringList,
  } = useContext(CeklistMonitoringContext);

  const [form] = useState(
    ceklistMonitoring.data && ceklistMonitoring.data.length > 0
      ? ceklistMonitoring.data.filter(
          (cm) => parseInt(cm.id) === parseInt(id)
        )[0]
      : {}
  );

  const [ceklistMonitoringListSelected, setCeklistMonitoringListSelected] =
    useState(
      form ? form.ceklist_monitoring_list.map((cml) => parseInt(cml.id)) : []
    );

  const handleSetCMLS = (id) => {
    setCeklistMonitoringListSelected([
      ...ceklistMonitoringListSelected,
      parseInt(id),
    ]);
  };

  const handleRemoveCMLS = (id) => {
    setCeklistMonitoringListSelected(
      ceklistMonitoringListSelected.filter((a) => parseInt(a) !== parseInt(id))
    );
  };

  const history = useHistory();

  const handleSubmit = async () => {
    try {
      const res = await patchCeklistMonitoring(id, {
        ...form,
        ceklist_monitoring_list_row: ceklistMonitoringListSelected,
      });
      if (res.data && res.data.msg) {
        onFetchData();
        history.push(mainUrl);
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  return (
    <>
      <div className="bg-gradient-primary border-bottom-warning rounded-3 p-3 mb-3">
        <table className="table table-sm table-borderless mb-0">
          <thead>
            <tr>
              <th className="border-bottom border-secondary bg-transparent text-gray-400 fw-normal">Cabang</th>
              <th className="border-bottom border-secondary bg-transparent text-gray-400 fw-normal">Departemen</th>
              <th className="border-bottom border-secondary bg-transparent text-gray-400 fw-normal">Auditor</th>
              <th className="border-bottom border-secondary bg-transparent text-gray-400 fw-normal">Kode Dokumen</th>
              <th className="border-bottom border-secondary bg-transparent text-gray-400 fw-normal">Revisi</th>
              <th className="border-bottom border-secondary bg-transparent text-gray-400 fw-normal">Tanggal Efektif</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="bg-transparent text-white">
                {cabang.length > 0 ?
                  cabang
                    .filter((c) => parseInt(c.id) === parseInt(form.cabang_id))
                    .map((c) => c.nama)
                  : '-'}
              </td>
              <td className="bg-transparent text-white">
                {departemen.length > 0 ?
                  departemen
                    .filter(
                      (d) => parseInt(d.id) === parseInt(form.departemen_id)
                    )
                    .map((c) => c.nama)
                  : '-'}
              </td>
              <td className="bg-transparent text-white">
                {auditor.length > 0 ?
                  auditor
                    .filter((a) => parseInt(a.id) === parseInt(form.auditor_id))
                    .map((c) => c.nama)
                  : '-'}
              </td>
              <td className="bg-transparent text-white">{form.kode_dokumen}</td>
              <td className="bg-transparent text-white">{form.revisi}</td>
              <td className="bg-transparent text-white">{form.tanggal_efektif}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-2">
          <h4 className="py-3 fw-semibold"><span className="bg-gradient-success rounded text-white px-1"><i className="fa fa-fw fa-check"/></span> Ceklist Monitoring List Aktif</h4>
          <div className="overflow-y-auto" style={{ maxHeight: "75vh" }}>
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th>No</Th>
                  <Th>Klausul</Th>
                  <Th>Deskripsi</Th>
                  <Th>Refrensi Audit</Th>
                  <Th><i className="fa fa-fw fa-cog"/></Th>
                </Tr>
              </Thead>
              <Tbody>
                {ceklistMonitoringList.length > 0 &&
                  ceklistMonitoringList
                    .filter((cml) =>
                      ceklistMonitoringListSelected.includes(parseInt(cml.id))
                    )
                    .map((cmls, index) => {
                      return (
                        <Tr key={index}>
                          <Td className="text-center">{index + 1}</Td>
                          <Td>{cmls.klausul}</Td>
                          <Td>{cmls.deskripsi}</Td>
                          <Td>{cmls.refrensi_audit}</Td>
                          <Td className="text-center text-nowrap">
                            <SmallButton onClick={() => handleRemoveCMLS(cmls.id)} type="custom" buttonColor="danger-2" icon="right-long" text="Nonaktifkan" />
                          </Td>
                        </Tr>
                      );
                    })}
              </Tbody>
            </TableNew>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <h4 className="py-3 fw-semibold"><span className="bg-gradient-danger rounded text-white px-1"><i className="fa fa-fw fa-xmark"/></span> Ceklist Monitoring List Nonaktif</h4>
          <div className="overflow-y-auto" style={{ maxHeight: "75vh" }}>
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th>No</Th>
                  <Th>Klausul</Th>
                  <Th>Deskripsi</Th>
                  <Th>Refrensi Audit</Th>
                  <Th><i className="fa fa-fw fa-cog"/></Th>
                </Tr>
              </Thead>
              <Tbody>
                {ceklistMonitoringList.length > 0 &&
                  ceklistMonitoringList
                    .filter(
                      (cml) =>
                        !ceklistMonitoringListSelected.includes(parseInt(cml.id))
                    )
                    .map((cmls, index) => {
                      return (
                        <Tr key={index}>
                          <Td className="text-center">{index + 1}</Td>
                          <Td>{cmls.klausul}</Td>
                          <Td>{cmls.deskripsi}</Td>
                          <Td>{cmls.refrensi_audit}</Td>
                          <Td className="text-center text-nowrap">
                            <SmallButton onClick={() => handleSetCMLS(cmls.id)} type="custom" buttonColor="success-2" icon="left-long" text="Aktifkan" />
                          </Td>
                        </Tr>
                      );
                    })}
              </Tbody>
            </TableNew>
          </div>
        </div>
      </div>

      <div className="btn-group mt-3">
        <button
          type="button"
          className="btn btn-success-2"
          onClick={handleSubmit}
        >
          Simpan
        </button>
        <Link type="button" className="btn btn-dark" to={mainUrl}>
          Tutup
        </Link>
      </div>
    </>
  );
};

export default List;
