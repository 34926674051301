import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  LayoutTable,
  LayoutThead,
  TableNoData,
  // TableNoData,
} from "../../../components/layouts/Table";
import SectionPagination from "../../../components/sections/Pagination";
import { userState } from "../../../storage/auth";
import { FilterJenis } from "../components/Filter";
import { CeklistMonitoringContext } from "../Routes";
import PageLoading from "../../../components/pages/Loading";

const Auditor = ({ mainUrl, onFetchData }) => {
  const user = useRecoilValue(userState);
  const {
    ceklistMonitoring,
    setPage,
    setType,
    cabang,
    departemen,
    auditor,
    isLoaded,
    setIsLoaded,
  } = useContext(CeklistMonitoringContext);

  useEffect(() => {
    onFetchData({ auditor_id: user.id });
    return () => {
      setIsLoaded(false);
    };
  }, [onFetchData, setIsLoaded, user.id]);

  return (
    <div>
      <div className="row mb-2">
        <div className="col-lg-4 mb-2">
          <FilterJenis onChange={(res) => setType(res)} />
        </div>
      </div>
      <LayoutTable>
        <LayoutThead>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Cabang</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Departemen</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Auditor</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Tahun</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Tahap</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Kode Dokumen</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Revisi</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Tanggal Efektif</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
            <span className="fa fa-fw fa-cog"></span>
          </th>
        </LayoutThead>
        <tbody>
          {ceklistMonitoring.data &&
            ceklistMonitoring.data.length > 0 ? (
            ceklistMonitoring.data.map((cm, index) => {
              return (
                <tr key={index}>
                  <td className="py-1 border-silver text-nowrap text-center">{index + 1}</td>
                  <td className="py-1 border-silver text-nowrap text-center">
                    {cabang.length > 0 &&
                      cabang
                        .filter(
                          (c) => parseInt(c.id) === parseInt(cm.cabang_id)
                        )
                        .map((c) => c.nama)}
                  </td>
                  <td className="py-1 border-silver text-nowrap">
                    {departemen.length > 0 &&
                      departemen
                        .filter(
                          (d) => parseInt(d.id) === parseInt(cm.departemen_id)
                        )
                        .map((c) => c.nama)}
                  </td>
                  <td className="py-1 border-silver text-nowrap">
                    {auditor.length > 0 &&
                      auditor
                        .filter(
                          (a) => parseInt(a.id) === parseInt(cm.auditor_id)
                        )
                        .map((c) => c.nama)}
                  </td>
                  <td className="py-1 border-silver text-nowrap text-center">{cm.tahun}</td>
                  <td className="py-1 border-silver text-nowrap text-center">{cm.tahap}</td>
                  <td className="py-1 border-silver text-nowrap text-center">{cm.kode_dokumen}</td>
                  <td className="py-1 border-silver text-nowrap text-center">{cm.revisi}</td>
                  <td className="py-1 border-silver text-nowrap text-center">{cm.tanggal_efektif}</td>
                  <td className="py-1 border-silver text-nowrap text-center">
                    <Link
                      className="btn btn-sm btn-success-2 border-0 py-0 text-sm rounded mx-1"
                      to={`${mainUrl}/auditor/${cm.id}`}
                    >
                      <i className="fa fa-fw fa-sm fa-pen" /> Edit
                    </Link>
                    <Link
                      className="btn btn-sm btn-primary-3 border-0 py-0 text-sm rounded mx-1"
                      to={`${mainUrl}/${cm.id}/report`}
                    >
                      <i className="fa fa-fw fa-sm fa-bullhorn" /> Report
                    </Link>
                  </td>
                </tr>
              );
            })
          ) : (
            <TableNoData col={10} />
          )}
        </tbody>
      </LayoutTable>
      <SectionPagination
        links={ceklistMonitoring.links}
        from={ceklistMonitoring.from}
        to={ceklistMonitoring.to}
        total={ceklistMonitoring.total}
        onChangePage={(res) => setPage(res)}
      />
    </div>
  );
};

export default Auditor;
