import React, { useCallback, useEffect, useState } from "react";
import {
  FilterBulan,
  FilterCabang,
  FilterTahun,
  defaultFilterBulan,
  defaultFilterTahun,
} from "../../components/layouts/TopContent";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { fetchAbsensiPegawai } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { formatDate, formatTimeStamp } from "../../helpers";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const AbsensiPegawaiHrd = ({ user }) => {
  const history = useHistory();  

  if (user.jabatan !== "Bagian SDM" && user.jabatan !== "IT Programmer") {
    history.push("/dashboard");
  }
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [absensiPegawai, setAbsensiPegawai] = useState([]);
  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    const res = await fetchAbsensiPegawai(
      page,
      `bulan=${bulan}&tahun=${tahun}&cabang=${cabang}`
    );
    if (res.data) {
      setTitle(res.data.title);
      setAbsensiPegawai(res.data.absensi_pegawai);
      setIsLoaded(true);
    }
  }, [setTitle, page, bulan, tahun, cabang]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-md">
          <FilterCabang pusat={true} onChange={(res) => setCabang(res)} />
        </div>
        <div className="col-md">
          <FilterBulan onChange={(res) => setBulan(res)} />
        </div>
        <div className="col-md">
          <FilterTahun onChange={(res) => setTahun(res)} />
        </div>
      </div>
      {isLoaded && absensiPegawai.length > 0 && (
        <>
          <TableNew>
            <Thead>
              <Tr className="text-center text-nowrap">
                <Th className='align-middle'>No</Th>
                <Th className='align-middle'>Nama Pegawai</Th>
                <Th className='align-middle'>Jenis</Th>
                <Th className='align-middle'>Tanggal</Th>
                <Th className='align-middle'>Jam Datang</Th>
                <Th className='align-middle'>Jam Pulang</Th>
                <Th className='align-middle'>Keterangan</Th>
                <Th className='align-middle'>Status</Th>
                <Th className='align-middle'>Nama Atasan</Th>
                <Th className='align-middle'>Created at</Th>
                <Th className='align-middle'>Updated at</Th>
              </Tr>
            </Thead>
            <Tbody>
              {absensiPegawai.map((ap, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle">{1 + index}</Td>
                    <Td>{ap.pegawai && ap.pegawai.nama}</Td>
                    <Td className="text-center">
                      {parseInt(ap.jenis) === 1 && (
                        <span className="text-info">Datang</span>
                      )}
                      {parseInt(ap.jenis) === 2 && (
                        <span className="text-success">Pulang</span>
                      )}
                      {parseInt(ap.jenis) === 3 && (
                        <span className="text-primary">Datang & Pulang</span>
                      )}
                    </Td>
                    <Td className="text-center">{formatDate(ap.tanggal)}</Td>
                    <Td className="text-center">
                      {ap.jam_datang ? ap.jam_datang : "-"}
                    </Td>
                    <Td className="text-center">
                      {ap.jam_pulang ? ap.jam_pulang : "-"}
                    </Td>
                    <Td>{ap.keterangan}</Td>
                    <Td className="text-center align-middle">
                      {parseInt(ap.status) === 1 && (
                        <span className="text-success">Approved</span>
                      )}
                      {parseInt(ap.status) === 2 && (
                        <span className="text-danger">Rejected</span>
                      )}
                      {!parseInt(ap.status) && (
                        <span className="text-warning">Waiting</span>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {ap.atasan && ap.atasan.nama}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatTimeStamp(ap.created_at)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatTimeStamp(ap.updated_at)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        </>
      )}
    </>
  );
};

export default AbsensiPegawaiHrd;
