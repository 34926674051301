import React, { useRef, useState } from "react";
import { postAngsuran, showAngsuran } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import PageLoading from "../../components/pages/Loading";
import { getJurusan, getCurrency, saAlert } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import FormInputCurrency from "../../components/forms/InputCurrency";

const MhsAngsuran = (props) => {
  const { onFetchData, mhs } = props;
  const [modal, setModal] = useState(false);
  const tagForm = useRef(null);
  const tagForm2 = useRef(null);

  const [show, setShow] = useState({
    bulan_1: false,
    bulan_5: false,
    bulan_9: false,
    bulan_13: false,
    bulan_17: false,
    bulan_21: false,
    optional: false,
  });

  const [form, setForm] = useState({});
  const [formOptional, setFormOptional] = useState({});
  const [pembayaranOptional, setPembayaranOptional] = useState({});
  const [biaya, setBiaya] = useState({});
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await showAngsuran("mhs_id", `mhs_id=${mhs.id}`);
      if (res.data) {
        res.data.angsuran && setForm(res.data.angsuran);
        res.data.angsuran_optional &&
          setPembayaranOptional(res.data.angsuran_optional);
        if (res.data.biaya) {
          setBiaya(res.data.biaya);
          res.data.biaya.angsuran &&
            setForm((prevState) => {
              return { ...prevState, angsuran: res.data.biaya.angsuran };
            });
        }
        // setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    postAngsuran({ ...form, mhs_id: mhs.id })
      .then((res) => {
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil update Data !");
        }
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
        }
      });
  };

  const handleSubmitOptional = (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);
    postAngsuran({ ...formOptional, mhs_id: mhs.id, optional: true })
      .then((res) => {
        if (res.data === "success") {
          onFetchData();
          // handleClose(false);
          handleClose();
          saAlert("success", "Berhasil update Data !");
        }
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
        }
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpen = () => {
    fetchData();
    setModal(true);
  };

  const handleClose = (closeModal = true) => {
    tagForm.current && tagForm.current.reset();
    tagForm2.current && tagForm2.current.reset();
    setIsLoading(false);
    setForm({});
    setFormOptional({});
    setPembayaranOptional({});
    setErrors({});
    if (closeModal) {
      setModal(false);
    }
  };

  const getTotalAngsuran = () => {
    let total =
      (form.pembayaran_1 ? parseInt(form.pembayaran_1) : 0) +
      (form.pembayaran_2 ? parseInt(form.pembayaran_2) : 0) +
      (form.pembayaran_3 ? parseInt(form.pembayaran_3) : 0) +
      (form.pembayaran_4 ? parseInt(form.pembayaran_4) : 0) +
      (form.pembayaran_5 ? parseInt(form.pembayaran_5) : 0) +
      (form.pembayaran_6 ? parseInt(form.pembayaran_6) : 0) +
      (form.pembayaran_7 ? parseInt(form.pembayaran_7) : 0) +
      (form.pembayaran_8 ? parseInt(form.pembayaran_8) : 0) +
      (form.pembayaran_9 ? parseInt(form.pembayaran_9) : 0) +
      (form.pembayaran_10 ? parseInt(form.pembayaran_10) : 0) +
      (form.pembayaran_11 ? parseInt(form.pembayaran_11) : 0) +
      (form.pembayaran_12 ? parseInt(form.pembayaran_12) : 0) +
      (form.pembayaran_13 ? parseInt(form.pembayaran_13) : 0) +
      (form.pembayaran_14 ? parseInt(form.pembayaran_14) : 0) +
      (form.pembayaran_15 ? parseInt(form.pembayaran_15) : 0) +
      (form.pembayaran_16 ? parseInt(form.pembayaran_16) : 0) +
      (form.pembayaran_17 ? parseInt(form.pembayaran_17) : 0) +
      (form.pembayaran_18 ? parseInt(form.pembayaran_18) : 0) +
      (form.pembayaran_19 ? parseInt(form.pembayaran_19) : 0) +
      (form.pembayaran_20 ? parseInt(form.pembayaran_20) : 0) +
      (form.pembayaran_21 ? parseInt(form.pembayaran_21) : 0) +
      (form.pembayaran_22 ? parseInt(form.pembayaran_22) : 0) +
      (form.pembayaran_23 ? parseInt(form.pembayaran_23) : 0) +
      (form.pembayaran_24 ? parseInt(form.pembayaran_24) : 0);

    let total_2 = 0;
    if (pembayaranOptional.length > 0) {
      total_2 = pembayaranOptional
        .map((po) => po.jumlah)
        .reduce((prev, curr) => parseInt(prev) + parseInt(curr));
    }
    return total + total_2;
  };

  const getDu = () => {
    let du;
    // if (parseInt(mhs.angkatan) < 2022) {
    //   if (mhs.jenjang === "D1") {
    //     du = 4000000;
    //   } else if (mhs.jenjang === "D2") {
    //     du = 5500000;
    //   }
    // } else {
    //   if (mhs.jenjang === "D1") {
    //     du = 4500000;
    //   } else if (mhs.jenjang === "D2") {
    //     du = 5500000;
    //   }
    // }
    if (biaya) {
      let du_1 = biaya.du_1 ? biaya.du_1 : 0;
      let du_2 = biaya.du_2 ? biaya.du_2 : 0;
      let du_3 = biaya.du_3 ? biaya.du_3 : 0;

      du = parseInt(du_1) + parseInt(du_2) + parseInt(du_3);
    }
    return du;
  };

  const getSisaAngsuran = () => {
    let total =
      parseInt(biaya.biaya_pendidikan || 0) -
      parseInt(biaya.subsidi || 0) -
      getDu() -
      parseInt(biaya.diskon_dpp || 0);
    let dibayar = getTotalAngsuran();
    let sisa =
      total -
      dibayar -
      parseInt(biaya.diskon_angsuran ? biaya.diskon_angsuran : 0);
    return sisa;
  };

  return (
    <>
      <SmallButton onClick={handleOpen} type='custom' icon='sack-dollar' buttonColor='warning-2'/>

      <LayoutModal
        title={`Biaya Mahasiswa ${mhs.nama}`}
        modal={modal}
        onClose={handleClose}
      >
        {!isLoading ? (
          <>
            <form ref={tagForm} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-9 mb-3">
                  <div className="bg-gradient-primary text-white rounded-3 p-3">
                    <table>
                      <tbody>
                        <tr>
                          <td className="text-gray-400">NIM</td>
                          <td className="px-3">:</td>
                          <td className="">{mhs.nim}</td>
                        </tr>
                        <tr>
                          <td className="text-gray-400">Nama</td>
                          <td className="px-3">:</td>
                          <td>{mhs.nama}</td>
                        </tr>
                        <tr>
                          <td className="text-gray-400">Jurusan</td>
                          <td className="px-3">:</td>
                          <td>{getJurusan(mhs.jurusan_id)}</td>
                        </tr>
                        <tr>
                          <td className="text-gray-400">Jenjang</td>
                          <td className="px-3">:</td>
                          <td>{mhs.jenjang}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <FormInputCurrency
                    className='d-flex justify-content-center align-items-center h-100'
                    label='Biaya Angsuran/Bulan'
                    name="angsuran"
                    value={form.angsuran}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    error={errors.angsuran}
                  />
                </div>
              </div>

              <TableNew copy={false}>
                <Thead>
                  <Tr className='text-center'>
                    <Th>Biaya Pendidikan</Th>
                    <Th>Subsidi</Th>
                    <Th>Biaya daftar ulang</Th>
                    <Th>Diskon DPP</Th>
                    <Th>Angsuran dibayar</Th>
                    <Th>Diskon Angsuran</Th>
                    <Th>Sisa Angsuran</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr className='text-center'>
                    <Td>{biaya && getCurrency(biaya.biaya_pendidikan || 0)}</Td>
                    <Td>{biaya && getCurrency(biaya.subsidi || 0)}</Td>
                    <Td>{getCurrency(getDu())}</Td>
                    <Td>{biaya && getCurrency(biaya.diskon_dpp || 0)}</Td>
                    <Td>{getCurrency(getTotalAngsuran())}</Td>
                    <Td>{biaya.diskon_angsuran ? getCurrency(biaya.diskon_angsuran) : 0}</Td>
                    <Td>{getCurrency(getSisaAngsuran())}</Td>
                  </Tr>
                </Tbody>
              </TableNew>

              {/* <div className="row">
                <div className="col-md">
                  <h3>NIM : {mhs.nim}</h3>
                  <FormInput
                    label={`Biaya Angsuran / Bulan <span class="text-success">Rp ${getCurrency(
                      form.angsuran,
                      0
                    )}</span>`}
                    name="angsuran"
                    value={form.angsuran}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    error={errors.angsuran}
                  />
                </div>
              </div> */}

              <div className="d-flex justify-content-center align-items-center">
                <div className="btn-group mb-3">
                  <SmallButton 
                    margin='py-1 px-4' 
                    type='custom' 
                    icon='eye-slash' 
                    buttonColor='dark' 
                    text='sembunyikan semua'
                    onClick={() =>
                      setShow({
                        bulan_1: false,
                        bulan_5: false,
                        bulan_9: false,
                        bulan_13: false,
                        bulan_17: false,
                        bulan_21: false,
                      })
                    }
                  />
                  <SmallButton 
                    margin='py-1 px-4' 
                    type='custom' 
                    icon='eye' 
                    buttonColor='primary-2' 
                    text='Tampilkan semua'
                    onClick={() =>
                      setShow({
                        bulan_1: true,
                        bulan_5: true,
                        bulan_9: true,
                        bulan_13: true,
                        bulan_17: true,
                        bulan_21: true,
                      })
                    }
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between aling-items-center bg-primary border-bottom-warning text-white rounded p-2 mb-2">
                <p className="mb-0">Pembayaran Bulan : 1 - 4{" "}</p>
                <SmallButton 
                  margin='ms-2 py-1 px-4' 
                  type='custom' 
                  icon={show.bulan_1 ? "eye-slash" : "eye"}
                  buttonColor={show.bulan_1 ? "dark" : "success-2"} 
                  text={show.bulan_1 ? "Sembunyikan" : "Tampilkan"}
                  onClick={() =>
                    setShow((prevState) => {
                      return { ...prevState, bulan_1: !show.bulan_1 };
                    })
                  }
                />
                {/* <span
                  className={`badge ${
                    show.bulan_1 ? "badge-secondary" : "badge-success"
                  }`}
                  type="button"
                  onClick={() =>
                    setShow((prevState) => {
                      return { ...prevState, bulan_1: !show.bulan_1 };
                    })
                  }
                >
                  {show.bulan_1 ? "Hide" : "Show"}
                </span> */}
              </div>
              <div
                className={`row mx-0 ${
                  show.bulan_1 ? "d-show" : "d-none"
                }`}
              >
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 1</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_1 ? form.pembayaran_1 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_1"
                        value={form.pembayaran_1}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_1}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_1"
                        value={form.tanggal_1}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_1}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_1"
                        value={form.denda_1}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_1}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_1 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_1 ? form.denda_1 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 2</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_2 ? form.pembayaran_2 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_2"
                        value={form.pembayaran_2}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_2}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_2"
                        value={form.tanggal_2}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_2}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_2"
                        value={form.denda_2}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_2}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_2 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_2 ? form.denda_2 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 3</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_3 ? form.pembayaran_3 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_3"
                        value={form.pembayaran_3}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_3}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_3"
                        value={form.tanggal_3}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_3}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_3"
                        value={form.denda_3}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_3}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_3 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_3 ? form.denda_3 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 4</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_4 ? form.pembayaran_4 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_4"
                        value={form.pembayaran_4}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_4}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_4"
                        value={form.tanggal_4}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_4}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_4"
                        value={form.denda_4}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_4}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_4 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_4 ? form.denda_4 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center bg-primary border-bottom-warning text-white rounded p-2 mb-2">
                <p className="mb-0">Pembayaran Bulan : 5 - 8{" "}</p>
                <SmallButton 
                  margin='ms-2 py-1 px-4' 
                  type='custom' 
                  icon={show.bulan_5 ? "eye-slash" : "eye"}
                  buttonColor={show.bulan_5 ? "dark" : "success-2"} 
                  text={show.bulan_5 ? "Sembunyikan" : "Tampilkan"}
                  onClick={() =>
                    setShow((prevState) => {
                      return { ...prevState, bulan_5: !show.bulan_5 };
                    })
                  }
                />
              </div>

              {/* <h4 className="text-center text-primary">
                Pembayaran Bulan 5 - 8{" "}
                <span
                  className={`badge ${
                    show.bulan_5 ? "badge-secondary" : "badge-success"
                  }`}
                  type="button"
                  onClick={() =>
                    setShow((prevState) => {
                      return { ...prevState, bulan_5: !show.bulan_5 };
                    })
                  }
                >
                  {show.bulan_5 ? "Hide" : "Show"}
                </span>
              </h4> */}
              <div
                className={`row mx-0 ${
                  show.bulan_5 ? "d-show" : "d-none"
                }`}
              >
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 5</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_5 ? form.pembayaran_5 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_5"
                        value={form.pembayaran_5}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_5}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_5"
                        value={form.tanggal_5}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_5}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_5"
                        value={form.denda_5}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_5}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_5 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_5 ? form.denda_5 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 6</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_6 ? form.pembayaran_6 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_6"
                        value={form.pembayaran_6}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_6}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_6"
                        value={form.tanggal_6}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_6}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_6"
                        value={form.denda_6}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_6}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_6 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_6 ? form.denda_6 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 7</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_7 ? form.pembayaran_7 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_7"
                        value={form.pembayaran_7}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_7}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_7"
                        value={form.tanggal_7}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_7}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_7"
                        value={form.denda_7}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_7}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_7 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_7 ? form.denda_7 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 8</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_8 ? form.pembayaran_8 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_8"
                        value={form.pembayaran_8}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_8}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_8"
                        value={form.tanggal_8}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_8}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_8"
                        value={form.denda_8}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_8}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_8 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_8 ? form.denda_8 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center bg-primary border-bottom-warning text-white rounded p-2 mb-2">
                <p className="mb-0">Pembayaran Bulan : 9 - 12{" "}</p>
                <SmallButton 
                  margin='ms-2 py-1 px-4' 
                  type='custom' 
                  icon={show.bulan_9 ? "eye-slash" : "eye"}
                  buttonColor={show.bulan_9 ? "dark" : "success-2"} 
                  text={show.bulan_9 ? "Sembunyikan" : "Tampilkan"}
                  onClick={() =>
                    setShow((prevState) => {
                      return { ...prevState, bulan_9: !show.bulan_9 };
                    })
                  }
                />
              </div>

              {/* <h4 className="text-center text-primary">
                Pembayaran Bulan 9 - 12{" "}
                <span
                  className={`badge ${
                    show.bulan_9 ? "badge-secondary" : "badge-success"
                  }`}
                  type="button"
                  onClick={() =>
                    setShow((prevState) => {
                      return { ...prevState, bulan_9: !show.bulan_9 };
                    })
                  }
                >
                  {show.bulan_9 ? "Hide" : "Show"}
                </span>
              </h4> */}
              <div
                className={`row mx-0 ${
                  show.bulan_9 ? "d-show" : "d-none"
                }`}
              >
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 9</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_9 ? form.pembayaran_9 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_9"
                        value={form.pembayaran_9}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_9}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_9"
                        value={form.tanggal_9}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_9}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_9"
                        value={form.denda_9}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_9}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_9 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_9 ? form.denda_9 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 10</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_10 ? form.pembayaran_10 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_10"
                        value={form.pembayaran_10}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_10}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_10"
                        value={form.tanggal_10}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_10}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_10"
                        value={form.denda_10}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_10}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_10 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_10 ? form.denda_10 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 11</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_11 ? form.pembayaran_11 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_11"
                        value={form.pembayaran_11}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_11}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_11"
                        value={form.tanggal_11}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_11}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_11"
                        value={form.denda_11}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_11}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_11 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_11 ? form.denda_11 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-1 mb-3">
                  <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                    <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                      <span>Bulan 12</span>
                    </div>
                    <div className="p-3">
                      <FormInput
                        label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                          form.pembayaran_12 ? form.pembayaran_12 : 0,
                          0
                        )}</span>`}
                        name="pembayaran_12"
                        value={form.pembayaran_12}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.pembayaran_12}
                      />
                      <FormInput
                        type="date"
                        label="Tanggal"
                        name="tanggal_12"
                        value={form.tanggal_12}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.tanggal_12}
                      />
                      <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                      <FormInput
                        type="number"
                        label="Jumlah Hari Denda"
                        name="denda_12"
                        value={form.denda_12}
                        onChange={(e) =>
                          setForm((prevState) => {
                            return {
                              ...prevState,
                              [e.target.name]: e.target.value,
                            };
                          })
                        }
                        error={errors.denda_12}
                      />
                      <span className="text-gray-900">
                        Denda : {form.denda_12 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_12 ? form.denda_12 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {mhs.jenjang === "D2" && (
                <>
                  <div className="d-flex justify-content-between align-items-center bg-primary border-bottom-warning text-white rounded p-2 mb-2">
                    <p className="mb-0">Pembayaran Bulan : 13 - 16{" "}</p>
                    <SmallButton
                      margin='ms-2 py-1 px-4'
                      type='custom'
                      icon={show.bulan_13 ? "eye-slash" : "eye"}
                      buttonColor={show.bulan_13 ? "dark" : "success-2"}
                      text={show.bulan_13 ? "Sembunyikan" : "Tampilkan"}
                      onClick={() =>
                        setShow((prevState) => {
                          return { ...prevState, bulan_13: !show.bulan_13 };
                        })
                      }
                    />
                  </div>

                  <div
                    className={`row mx-0 ${
                      show.bulan_13 ? "d-show" : "d-none"
                    }`}
                  >
                    <div className="col-md-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 13</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_13 ? form.pembayaran_13 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_13"
                            value={form.pembayaran_13}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_13}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_13"
                            value={form.tanggal_13}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_13}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_13"
                            value={form.denda_13}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_13}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_13 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_13 ? form.denda_13 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 14</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_14 ? form.pembayaran_14 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_14"
                            value={form.pembayaran_14}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_14}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_14"
                            value={form.tanggal_14}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_14}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_14"
                            value={form.denda_14}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_14}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_14 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_14 ? form.denda_14 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 15</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_15 ? form.pembayaran_15 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_15"
                            value={form.pembayaran_15}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_15}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_15"
                            value={form.tanggal_15}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_15}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_15"
                            value={form.denda_15}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_15}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_15 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_15 ? form.denda_15 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 16</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_16 ? form.pembayaran_16 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_16"
                            value={form.pembayaran_16}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_16}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_16"
                            value={form.tanggal_16}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_16}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_16"
                            value={form.denda_16}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_16}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_16 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_16 ? form.denda_16 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center bg-primary border-bottom-warning text-white rounded p-2 mb-2">
                    <p className="mb-0">Pembayaran Bulan : 17 - 20{" "}</p>
                    <SmallButton
                      margin='ms-2 py-1 px-4'
                      type='custom'
                      icon={show.bulan_17 ? "eye-slash" : "eye"}
                      buttonColor={show.bulan_17 ? "dark" : "success-2"}
                      text={show.bulan_17 ? "Sembunyikan" : "Tampilkan"}
                      onClick={() =>
                        setShow((prevState) => {
                          return { ...prevState, bulan_17: !show.bulan_17 };
                        })
                      }
                    />
                  </div>

                  <div
                    className={`row mx-0 ${
                      show.bulan_17 ? "d-show" : "d-none"
                    }`}
                  >
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 17</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_17 ? form.pembayaran_17 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_17"
                            value={form.pembayaran_17}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_17}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_17"
                            value={form.tanggal_17}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_17}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_17"
                            value={form.denda_17}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_17}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_17 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_17 ? form.denda_17 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 18</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_18 ? form.pembayaran_18 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_18"
                            value={form.pembayaran_18}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_18}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_18"
                            value={form.tanggal_18}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_18}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_18"
                            value={form.denda_18}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_18}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_18 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_18 ? form.denda_18 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 19</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_19 ? form.pembayaran_19 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_19"
                            value={form.pembayaran_19}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_19}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_19"
                            value={form.tanggal_19}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_19}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_19"
                            value={form.denda_19}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_19}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_19 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_19 ? form.denda_19 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 20</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_20 ? form.pembayaran_20 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_20"
                            value={form.pembayaran_20}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_20}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_20"
                            value={form.tanggal_20}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_20}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_20"
                            value={form.denda_20}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_20}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_20 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_20 ? form.denda_20 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center bg-primary border-bottom-warning text-white rounded p-2 mb-2">
                    <p className="mb-0">Pembayaran Bulan : 21 - 24{" "}</p>
                    <SmallButton
                      margin='ms-2 py-1 px-4'
                      type='custom'
                      icon={show.bulan_21 ? "eye-slash" : "eye"}
                      buttonColor={show.bulan_21 ? "dark" : "success-2"}
                      text={show.bulan_21 ? "Sembunyikan" : "Tampilkan"}
                      onClick={() =>
                        setShow((prevState) => {
                          return { ...prevState, bulan_21: !show.bulan_21 };
                        })
                      }
                    />
                  </div>
                  
                  <div
                    className={`row mx-0 ${
                      show.bulan_21 ? "d-show" : "d-none"
                    }`}
                  >
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 21</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_21 ? form.pembayaran_21 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_21"
                            value={form.pembayaran_21}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_21}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_21"
                            value={form.tanggal_21}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_21}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_21"
                            value={form.denda_21}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_21}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_21 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_21 ? form.denda_21 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 22</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_22 ? form.pembayaran_22 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_22"
                            value={form.pembayaran_22}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_22}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_22"
                            value={form.tanggal_22}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_22}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_22"
                            value={form.denda_22}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_22}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_22 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_22 ? form.denda_22 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 23</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_23 ? form.pembayaran_23 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_23"
                            value={form.pembayaran_23}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_23}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_23"
                            value={form.tanggal_23}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_23}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_23"
                            value={form.denda_23}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_23}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_23 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_23 ? form.denda_23 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 px-1 mb-3">
                      <div className="bg-silver-2 border border-silver rounded-3 shadow-sm">
                        <div className="text-center bg-silver border-bottom border-silver rounded-top-3 text-black fw-medium py-2">
                          <span>Bulan 24</span>
                        </div>
                        <div className="p-3">
                          <FormInput
                            label={`Pembayaran <span class="fw-normal text-black">Rp.${getCurrency(
                              form.pembayaran_24 ? form.pembayaran_24 : 0,
                              0
                            )}</span>`}
                            name="pembayaran_24"
                            value={form.pembayaran_24}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.pembayaran_24}
                          />
                          <FormInput
                            type="date"
                            label="Tanggal"
                            name="tanggal_24"
                            value={form.tanggal_24}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.tanggal_24}
                          />
                          <span className="text-sm text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></span>
                          <FormInput
                            type="number"
                            label="Jumlah Hari Denda"
                            name="denda_24"
                            value={form.denda_24}
                            onChange={(e) =>
                              setForm((prevState) => {
                                return {
                                  ...prevState,
                                  [e.target.name]: e.target.value,
                                };
                              })
                            }
                            error={errors.denda_24}
                          />
                          <span className="text-gray-900">
                            Denda : {form.denda_24 ? <span className="text-danger-3">Rp.{getCurrency((form.denda_24 ? form.denda_24 : 0) * 5000, 0)}</span> : 'Tidak ada'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <FormTextarea
                label="Catatan"
                name="catatan"
                value={form.catatan}
                onChange={(e) =>
                  setFormOptional((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.catatan}
              />

              <div className="btn-group">
                <button type="submit" className="btn btn-success-2 px-4">
                  Simpan Perubahaan
                </button>
                {/* <button
                  type="button"
                  className="btn btn-dark px-4"
                  onClick={handleClose}
                >
                  Kembali
                </button> */}
              </div>
            </form>

            <form
              ref={tagForm2}
              className="row mt-3"
              onSubmit={handleSubmitOptional}
            >
              <div className="col-12 text-center">
                <h4 className="fw-semibold text-black">Pembayaran Opsional</h4>
                <p className="w-lg-50 mx-auto text-dark">
                  Pembayaran ini digunakan untuk Mahasiswa yang membayar dengan
                  jumlah angsuran yang lebih lama dari Mahasiswa lainnya seperti
                  Mahasiswa KDBB.
                </p>
              </div>
              <div className="col-6">
                <TableNew copy={false}>
                  <Thead>
                    <Tr className="text-center">
                      <Th>No</Th>
                      <Th>Pembayaran</Th>
                      <Th>Jumlah</Th>
                      <Th>Tanggal</Th>
                      <Th>Denda</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {pembayaranOptional.length > 0 ?
                      pembayaranOptional.map((po, index) => {
                        return (
                          <Tr key={index}>
                            <Td className="text-center">{index + 1}</Td>
                            <Td className="text-center">{po.ke}</Td>
                            <Td className="text-start">{po.jumlah}</Td>
                            <Td className="text-center">{po.tanggal}</Td>
                            <Td className="text-start">{po.denda * 5000}</Td>
                          </Tr>
                        );
                      })
                      : (
                        <Tr>
                          <Td colSpan="5" className="text-center">
                            <p className="mb-0 py-5">Tidak ada data.</p>
                          </Td>
                        </Tr>
                      )}
                  </Tbody>
                </TableNew>
              </div>
              <div className="col-6">
                <FormInput
                  label={`Pembayaran Optional <span class="fw-normal text-black">Rp ${getCurrency(
                    formOptional.jumlah ? formOptional.jumlah : 0,
                    0
                  )}</span>`}
                  name="jumlah"
                  value={formOptional.jumlah}
                  onChange={(e) =>
                    setFormOptional((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                  error={errors.jumlah}
                />
                <div className="row">
                  <div className="col-6">
                    <FormInput
                      type="number"
                      label={`Pembayaran ke - ${formOptional.ke ? formOptional.ke : '?'}`}
                      name="ke"
                      value={formOptional.ke}
                      onChange={(e) =>
                        setFormOptional((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        })
                      }
                      error={errors.ke}
                    />
                  </div>
                  <div className="col-6">
                    <FormInput
                      type="date"
                      label="Tanggal"
                      name="tanggal"
                      value={formOptional.tanggal}
                      onChange={(e) =>
                        setFormOptional((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        })
                      }
                      error={errors.tanggal}
                    />
                  </div>
                </div>
                <p className="text-gray-900">Denda : <span className="text-black">Rp 5,000/Hari</span></p>
                <FormInput
                  type="number"
                  label="Jumlah Hari Denda"
                  name="denda"
                  value={formOptional.denda}
                  onChange={(e) =>
                    setFormOptional((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                  error={errors.denda}
                />
                <p className="text-gray-900">
                  Denda : {formOptional.denda ? <span className="text-danger-3">Rp.{getCurrency((formOptional.denda ? formOptional.denda : 0) * 5000, 0)}</span> : 'Tidak ada'}
                </p>
                <button className="btn btn-success-2">
                  Tambah Pembayaran Optional
                </button>
              </div>
            </form>
          </>
        ) : (
          <PageLoading />
        )}
      </LayoutModal>
    </>
  );
};

export default MhsAngsuran;
