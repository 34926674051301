import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKpi, fetchMorningReport } from "../../api";
import { titleState } from "../../storage/pages";
import MorningReportCreate from "./Create";
import { FilterSearch } from "../../components/layouts/TopContent";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import MorningReportEdit from "./Edit";
import SectionPagination from "../../components/sections/Pagination";
import MorningReportDelete from "./Delete";
import { formatDate } from "../../helpers";
import { userState } from "../../storage/auth";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const MorningReportHrd = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);

  const [morningReport, setMorningReport] = useState({});

  const fetchData = useCallback(
    async ({ isReset = true } = true) => {
      {
        isReset && setIsLoaded(false);
      }
      try {
        const res = await fetchMorningReport(page, `search=${search}&hrd=1`);
        setIsLoaded(true);
        setTitle(res.data.title);
        setMorningReport(res.data.morning_report);
      } catch (err) {
        //
      }
    },
    [page, search, setTitle]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [kpi, setKpi] = useState({});

  useEffect(() => {
    const fetchDataKpi = async () => {
      try {
        const res = await fetchKpi("all", `pegawai_id=${user.id}`);
        setKpi(res.data.kpi);
        console.log(res.data.kpi);
      } catch (err) {
        //
      }
    };
    fetchDataKpi();
  }, [user]);

  return (
    <>
      <div className="row justify-content-end mb-2">
        <div className="col-md-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>

      {isLoaded && (
        <TableNew>
          <Thead>
            <Tr className="text-center">
              <Th className="align-middle" rowSpan={2}>
                No
              </Th>
              <Th className="align-middle" rowSpan={2}>
                Nama
              </Th>
              <Th className="align-middle" rowSpan={2}>
                Tanggal
              </Th>
              <Th className="align-middle" colSpan={4}>
                List
              </Th>
            </Tr>
            <Tr className="text-center">
              <Th className="align-middle">No List</Th>
              <Th className="align-middle">KPI</Th>
              <Th className="align-middle">Judul</Th>
              <Th className="align-middle">Keterangan</Th>
            </Tr>
          </Thead>
          <Tbody>
            {morningReport.data &&
              morningReport.data.length > 0 &&
              morningReport.data.map((mr, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle text-nowrap">
                      {index + morningReport.from}
                    </Td>
                    <Td className="text-center align-middle text-nowrap">
                      {mr.pegawai && mr.pegawai.nama}
                    </Td>
                    <Td className="text-center align-middle text-nowrap">
                      {formatDate(mr.tanggal)}
                    </Td>
                    <Td paddingNone className="text-nowrap text-center">
                      <ol style={{ listStyleType: "none" }} className="p-0 mb-0">
                        {mr.morning_report_list &&
                          mr.morning_report_list.map((mrl, index) => {
                            return <li key={index} className="border-bottom border-silver px-2 py-0">{index + 1}</li>;
                          })}
                      </ol>
                    </Td>
                    <Td paddingNone className="text-nowrap">
                      <ol style={{ listStyleType: "none" }} className="p-0 mb-0">
                        {mr.morning_report_list &&
                          mr.morning_report_list.map((mrl, index) => {
                            return (
                              <li key={index} className="border-bottom border-silver px-2 py-0">{mrl.kpi && mrl.kpi.nama}</li>
                            );
                          })}
                      </ol>
                    </Td>
                    <Td paddingNone className="text-nowrap">
                      <ol style={{ listStyleType: "none" }} className="p-0 mb-0">
                        {mr.morning_report_list &&
                          mr.morning_report_list.map((mrl, index) => {
                            return <li key={index} className="border-bottom border-silver px-2 py-0">{mrl.judul}</li>;
                          })}
                      </ol>
                    </Td>
                    <Td paddingNone className="text-nowrap">
                      <ol style={{ listStyleType: "none" }} className="p-0 mb-0">
                        {mr.morning_report_list &&
                          mr.morning_report_list.map((mrl, index) => {
                            return <li key={index} className="border-bottom border-silver px-2 py-0">{mrl.keterangan}</li>;
                          })}
                      </ol>
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </TableNew>
      )}

      <SectionPagination
        links={morningReport.links}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default MorningReportHrd;
