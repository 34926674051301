import React from "react";
import { MultipleMenu, SinggleMenu } from "./Sidebar";

const Sdm = ({ user }) => {
  if (user.cabang === "pusat") {
    return (
      <>
        {user.jabatan === "IT Programmer" || user.group === "SDM" ? (
          <>
            <hr className="border-0 my-2" />

            <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
              KSU Pusat
            </div>

            <hr className="sidebar-divider border-0 mt-2 mb-0" />

            <SinggleMenu
              title="Soal Penilaian 360"
              fa="fa-table-columns"
              link="/soal-penilaian"
            />

            <MultipleMenu
              title="Pegawai"
              fa="fa-users"
              links={[
                {header: "Pegawai"},
                { title: "Master Pegawai", link: "/pegawai-master" },
                { title: "Pegawai", link: "/pegawai-hrd" },
                { title: "Jabatan", link: "/jabatan" },
                { title: "Direktorat", link: "/direktorat-hrd" },
                { title: "Divisi", link: "/divisi-hrd" },
                { title: "Departemen", link: "/departemen-hrd" },
                { title: "Pelaksana", link: "/pelaksana-hrd" },
                
                {header: "Penilaian"},
                { title: "KPI", link: "/kpi" },
                { title: "Aspek BSC", link: "/aspek-bsc" },
              ]}
            />
            <SinggleMenu
              title="Purhcase Request"
              fa="fa-cogs"
              link="/purchase-request/pusat"
            />

            <SinggleMenu
              title="RKAT"
              fa="fa-sack-dollar"
              link="/rkat"
            />

            <SinggleMenu
              title="Arus Kas"
              fa="fa-chart-line"
              link="/arus-kas"
            />

            <SinggleMenu
              title="Daily Report"
              fa="fa-cogs"
              link="/daily-report-hrd"
            />

            <SinggleMenu
              title="Laporan Audit KPI"
              fa="fa-file"
              link="/kpi-laporan"
            />

            {/* <MultipleMenu
              title="Keuangan"
              fa="fa-money-bill"
              links={[
                { title: "RKAT", link: "/rkat-pusat" },
              ]}
            /> */}

            {(user.jabatan === "Bagian SDM" || user.jabatan === "IT Programmer") && (
              <>
                <MultipleMenu
                  title="SDM"
                  fa="fa-users"
                  links={[
                    { title: "Lembur", link: "/lembur" },
                    { title: "Cuti", link: "/cuti" },
                    { title: "Ijin", link: "/Ijin" },
                    { title: "Meninggalkan Tugas", link: "/meninggalkan-tugas" },
                    { title: "Ijin Absensi", link: "/absensi-pegawai" },
                  ]}
                />
                <MultipleMenu
                  title="Penilaian Kompetensi"
                  fa="fa-users"
                  links={[
                    { title: "Form Penilaian", link: "/soal-penilaian" },
                    {
                      title: "Hasil Penilaian",
                      link: "/hasil-penilaian-kompetensi",
                    },
                    {
                      title: "Struktur Penilaian",
                      link: "/penilaian-kompetensi-struktur",
                    },
                  ]}
                />
              </>
            )}
          </>
        ) : null}
      </>
    );
  }

  return "";
};

export default Sdm;
