import React, { useRef } from 'react'
import { saAlert } from "../../helpers";

function TableNew(props) {

    const { tableResponsive = true, children, copy = true, striped = true, hover = true } = props

    const tableTag = useRef();
    const copyToClipboard = () => {
      const elTable = tableTag.current;
  
      let range, sel;
  
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
  
        try {
          range.selectNodeContents(elTable);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(elTable);
          sel.addRange(range);
        }
  
        document.execCommand("copy");
      }
  
      sel.removeAllRanges();
  
      saAlert(
        "success",
        "Berhasil copy tabel ke papan clip",
        "Data dapat dipaste ke excel maupun word."
      );
    };
  
    return (
      <div className="pt-3">
        <div className="position-relative">
          {copy && (
            <button
              className="position-absolute btn btn-warning-2 btn-sm text-sm rounded-top rounded-bottom-0 px-3 py-0 fw-semibold text-nowrap"
              style={{left: '0px', top: '-17px'}}
              onClick={copyToClipboard}
              title="Copy data ke clipboard."
            >
              <i className="fa fa-fw fa-copy me-1"></i>
              Copy
            </button>
          )}
          <div className={`${tableResponsive && 'table-responsive'} mb-3`}>
            <table
              className={`table table-sm table-bordered ${hover && 'table-hover'} ${
                striped && "table-striped"
              }`}
              ref={tableTag}
            >
              {children}
            </table>
          </div>
        </div>
      </div>
    );
}

export const Thead = (props) => {
    const {children} = props
    return (
        <thead>{children}</thead>
    )
}

export const Tbody = (props) => {
    const {children} = props
    return (
        <tbody>{children}</tbody>
    )
}

export const Tr = (props) => {
    const {className, children} = props
    return (
        <tr className={`${className}`}>{children}</tr>
    )
}

export const Th = (props) => {
    const {className, style, children, colSpan, rowSpan} = props
    return (
        <th colSpan={colSpan} rowSpan={rowSpan} className={`py-1 bg-primary text-white border-bottom border-silver fw-normal ${className}`} style={style}>{children}</th>
    )
}

export const Td = (props) => {
    const {className, style, children, colSpan, rowSpan, paddingNone} = props
    return (
        <td colSpan={colSpan} rowSpan={rowSpan} className={`${paddingNone ? 'p-0' : 'py-1'} border-silver ${className}`} style={style}>{children}</td>
    )
}

export default TableNew