import React, { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKrs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterAngkatan,
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
  FilterStatus,
} from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { getGrade, getJenjang, getJurusanShort, getOnOffMhs } from "../../helpers";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const NilaiUmumAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const defAngkatan = useRecoilValue(angkatanState);

  useEffect(() => setTitle("Nilai Umum"), [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [pagination, setPagination] = useState(10);
  const [jenjang, setJenjang] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [cabang, setCabang] = useState("");
  const [status, setStatus] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchKrs(
        page,
        `with_mhs=true&with_kelas=true&order_by_nim=true&search=${search}&angkatan=${angkatan}&pagination=${pagination}&jenjang=${jenjang}&jurusan=${jurusan}&cabang=${cabang}&status=${status}`
      );
      if (res.data && res.data.krs) {
        setItems(res.data.krs);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, search, angkatan, pagination, jenjang, jurusan, cabang, status]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <>
      {/* Filter 1 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterJurusan
            onChange={(res) => {
              setJurusan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterJenjang
            onChange={(res) => {
              setJenjang(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      {/* Filter 2 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterStatus
            onChange={(res) => {
              setStatus(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterAngkatan
            value={angkatan}
            onChange={(res) => {
              setAngkatan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterPagination
            onChange={(res) => {
              setPagination(res);
              setPage(1);
            }}
          />
        </div>
      </div>

      {/* filter 3 */}
      {user.cabang === "pusat" && (
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterCabang
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
        </div>
      )}

      <TextOnOffMhs />

      <div className="row">
        <div className="col-2 pe-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th className="text-nowrap">No</Th>
                <Th className="text-nowrap">NIM</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((k, index) => {
                  return (
                    <Tr key={index} className={getOnOffMhs(k.mhs)}>
                      <Td className="bg-none text-center">{items.from + index}</Td>
                      <Td className="bg-none text-center">{k.mhs && k.mhs.nim}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
        <div className="col-7 px-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th className="text-nowrap">Nama</Th>
                <Th>Jenjang</Th>
                <Th>Jurusan</Th>
                <Th className="text-nowrap">Kode Kelas</Th>
                <Th className="text-nowrap">Kode MK</Th>
                <Th className="text-nowrap">Mata Kuliah</Th>
                <Th className="text-nowrap">Semester</Th>
                <Th className="text-nowrap">Nama Dosen</Th>
                {/* <Th className="text-nowrap">
                  <i className="fa fa-bars" />
                </Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((k, index) => {
                  return (
                    <Tr key={index} className={getOnOffMhs(k.mhs)}>
                      <Td className="bg-none text-nowrap">{k.mhs && k.mhs.nama}</Td>
                      <Td className="bg-none text-nowrap text-center">
                        {k.mhs && getJenjang(k.mhs)}
                      </Td>
                      <Td className="bg-none text-nowrap text-center">
                        {getJurusanShort(k.mhs && k.mhs.jurusan_id)}
                      </Td>
                      <Td className="bg-none text-nowrap text-center">
                        {k.kelas && k.kelas.kode}
                      </Td>
                      <Td className="bg-none text-nowrap text-center">
                        {k.matkul && k.matkul.kode}
                      </Td>
                      <Td className="bg-none text-nowrap">
                        {k.matkul && k.matkul.nama}
                      </Td>
                      <Td className="bg-none text-nowrap text-center">
                        {k.kelas && k.kelas.semester}
                      </Td>
                      <Td className="bg-none text-nowrap">{k.dosen && k.dosen.nama}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
        <div className="col-3 ps-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th className="text-nowrap">Kehadiran</Th>
                <Th className="text-nowrap">Tugas</Th>
                <Th className="text-nowrap">UTS</Th>
                <Th className="text-nowrap">UAS</Th>
                <Th className="text-nowrap">Total</Th>
                <Th className="text-nowrap">Grade</Th>
                {/* <Th className="text-nowrap">
                  <i className="fa fa-bars" />
                </Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((k, index) => {
                  return (
                    <Tr key={index} className={getOnOffMhs(k.mhs)}>
                      <Td className="bg-none text-center">
                        {k.nilai && k.nilai.absensi}
                      </Td>
                      <Td className="bg-none text-center">
                        {k.nilai && k.nilai.tugas}
                      </Td>
                      <Td className="bg-none text-center">{k.nilai && k.nilai.uts}</Td>
                      <Td className="bg-none text-center">{k.nilai && k.nilai.uas}</Td>
                      <Td className="bg-none text-center">
                        {k.nilai && k.nilai.total}
                      </Td>
                      <Td className="bg-none text-center">
                        <span className="fw-semibold text-warning-3">{getGrade(k.nilai && k.nilai.grade)}</span>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
      </div>
      {items.links && (
        <SectionPagination
          from={items.from}
          to={items.to}
          total={items.total}
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

export default NilaiUmumAkademik;
