import React, { useState, useCallback, useEffect, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchMhs, patchMhs, patchNilaiAkhir } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterAngkatan,
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
  FilterStatus,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import SectionPagination from "../../components/sections/Pagination";
import { formatDate, getOnOffMhs, getOrigin, getThName, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const NilaiAkhirAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const defAngkatan = useRecoilValue(angkatanState);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => setTitle("Nilai Akhir"), [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [cabang, setCabang] = useState("");
  const [status, setStatus] = useState("");

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await fetchMhs(
        page,
        `search=${search}&angkatan=${angkatan}&pagination=${pagination}&jenjang=${jenjang}&jurusan=${jurusan}&cabang=${cabang}&status=${status}`
      );
      if (res.data && res.data.mhs) {
        setIsLoaded(true);
        setItems(res.data.mhs);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, search, angkatan, pagination, jenjang, jurusan, cabang, status]);

  useEffect(() => fetchData(), [fetchData]);

  // const handleUpdate = (id, status) => {
  //   saConfirm(
  //     "warning",
  //     `Yakin ubah status menjadi ${status === null ? "Bersyarat" : "Tidak Bersyarat"} ?`
  //   ).then(async (res) => {
  //     if (res.isConfirmed) {
  //       setErrors({});
  //       try {
  //         const res = await patchMhs(id, {
  //           status,
  //           nonaktifkan: true,
  //           ...form,
  //         });
  //         if (res.data === "success") {
  //           onFetchData();
  //           status === null && handleClose();
  //           saAlert("success", "Berhasil update data mahasiswa");
  //         }
  //       } catch (err) {
  //         err.response &&
  //           err.response.status === 422 &&
  //           setErrors(err.response.data.errors);
  //       }
  //     }
  //   });
  // };

  const handleBersyarat = async (mhs) => {
    try {
      const res = await patchMhs(mhs.id, {
        lulus_bersyarat: `${mhs.lulus_bersyarat === 1 ? 9 : 1}`,
      });
      if (res.data === "success") {
        fetchData();
        saAlert("success", "Berhasil update data mahasiswa.");
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  return (
    <>
      {/* Filter 1 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterJurusan
            onChange={(res) => {
              setJurusan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterJenjang
            onChange={(res) => {
              setJenjang(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      {/* Filter 2 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterStatus
            onChange={(res) => {
              setStatus(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterAngkatan
            value={angkatan}
            onChange={(res) => {
              setAngkatan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterPagination
            onChange={(res) => {
              setPagination(res);
              setPage(1);
            }}
          />
        </div>
      </div>

      {/* filter 3 */}
      {user.cabang === "pusat" && (
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterCabang
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
        </div>
      )}

      <TextOnOffMhs />
      {angkatan && jenjang && (
        <button
          className="btn btn-primary mb-2"
          onClick={() =>
            window.open(
              `${getOrigin()}/nilai-akhir/${user.cabang}/${angkatan}/${jenjang}`
            )
          }
        >
          <i className="fa fa-print"></i> Lihat data Lengkap
        </button>
      )}
      {isLoaded ? (
        <div className="row">
          <div className="col-2 pe-0">
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th>no</Th>
                  <Th>id</Th>
                  <Th>nim</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.data &&
                  items.data.length > 0 &&
                  items.data.map((m, index) => {
                    return (
                      <Tr key={index} className={getOnOffMhs(m)}>
                        <Td className="text-center">{items.from + index}</Td>
                        <Td className="text-center">{m.id}</Td>
                        <Td className="text-center">{m.nim}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </TableNew>
          </div>
          <div className="col-7 px-0">
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th className="text-nowrap">nama</Th>
                  {jenjang === "D1" && (
                    <>
                      <Th className="text-nowrap">
                        {getThName("laporan_tugas_akhir")}
                      </Th>
                      <Th className="text-nowrap">
                        {getThName("judul_laporan_tugas_akhir")}
                      </Th>
                      <Th className="text-nowrap">
                        {getThName("tanggal_laporan_tugas_akhir")}
                      </Th>
                    </>
                  )}
                  {jenjang === "D2" && (
                    <>
                      <Th className="text-nowrap">
                        {getThName("proyek_khusus")}
                      </Th>
                      <Th className="text-nowrap">
                        {getThName("judul_proyek_khusus")}
                      </Th>
                      <Th className="text-nowrap">
                        {getThName("tanggal_proyek_khusus")}
                      </Th>
                      <Th className="text-nowrap">{getThName("tugas_akhir")}</Th>
                      <Th className="text-nowrap">
                        {getThName("judul_tugas_akhir")}
                      </Th>
                      <Th className="text-nowrap">
                        {getThName("tanggal_tugas_akhir")}
                      </Th>
                    </>
                  )}
                  <Th className="text-nowrap">{getThName("no_ijazah")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.data &&
                  items.data.length > 0 &&
                  items.data.map((m, index) => {
                    return (
                      <Tr key={index} className={getOnOffMhs(m)}>
                        <Td className="text-nowrap">{m.nama}</Td>
                        {jenjang === "D1" && (
                          <>
                            <Td className="text-nowrap text-center bg-success text-white">
                              {m.nilai_akhir &&
                                m.nilai_akhir.laporan_tugas_akhir}
                            </Td>
                            <Td className="text-nowrap bg-success text-white">
                              {m.nilai_akhir &&
                                m.nilai_akhir.judul_laporan_tugas_akhir}
                            </Td>
                            <Td className="text-nowrap text-center bg-success text-white">
                              {m.nilai_akhir &&
                                formatDate(
                                  m.nilai_akhir.tanggal_laporan_tugas_akhir
                                )}
                            </Td>
                          </>
                        )}
                        {jenjang === "D2" && (
                          <>
                            <Td className="text-nowrap text-center bg-info text-white">
                              {m.nilai_akhir && m.nilai_akhir.proyek_khusus}
                            </Td>
                            <Td className="text-nowrap bg-info text-white">
                              {m.nilai_akhir &&
                                m.nilai_akhir.judul_proyek_khusus}
                            </Td>
                            <Td className="text-nowrap text-center bg-info text-white">
                              {m.nilai_akhir &&
                                formatDate(m.nilai_akhir.tanggal_proyek_khusus)}
                            </Td>
                            <Td className="text-nowrap text-center bg-success text-white">
                              {m.nilai_akhir && m.nilai_akhir.tugas_akhir}
                            </Td>
                            <Td className="text-nowrap bg-success text-white">
                              {m.nilai_akhir && m.nilai_akhir.judul_tugas_akhir}
                            </Td>
                            <Td className="text-nowrap text-center bg-success text-white">
                              {m.nilai_akhir &&
                                formatDate(m.nilai_akhir.tanggal_tugas_akhir)}
                            </Td>
                          </>
                        )}
                        <Td className="text-nowrap">
                          {m.nilai_akhir && m.nilai_akhir.no_ijasah
                            ? m.nilai_akhir.no_ijasah
                            : "-"}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </TableNew>
          </div>
          <div className="col-3 ps-0">
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th><i className="fa fa-fw fa-cog" /></Th>
                  <Th className="text-nowrap">Lulus Bersyarat</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.data &&
                  items.data.length > 0 &&
                  items.data.map((m, index) => {
                    return (
                      <Tr key={index} className={getOnOffMhs(m)}>
                        <Td className="text-center">
                          <Edit
                            mhs={m}
                            nilai_akhir={m.nilai_akhir ? m.nilai_akhir : {}}
                            onFetchData={fetchData}
                          />
                        </Td>
                        <Td className="position-relative text-center">
                          {/* <div className="position-absolute start-50 top-50 translate-x-50 translate-y-50">
                            <div className="form-check form-switch">
                              <input onChange={() => handleBersyarat(m)} className="form-check-input border-dark" type="checkbox" role="switch" checked={m.lulus_bersyarat === 1}/>
                            </div>
                          </div> */}
                          {m.lulus_bersyarat === 1 ? (
                            <SmallButton onClick={() => handleBersyarat(m)} type="custom" icon="fa fa-fw fa-check" buttonColor="danger-2" text="Ya"/>
                          ) : (
                            <SmallButton onClick={() => handleBersyarat(m)} type="custom" icon="fa fa-fw fa-xmark" buttonColor="success-2" text="Tidak"/>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </TableNew>
          </div>
        </div>
      ) : (
        <PageLoading />
      )}

      {items.links && (
        <SectionPagination
          from={items.from}
          to={items.to}
          total={items.total}
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

const Edit = ({ mhs, nilai_akhir, onFetchData }) => {
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleOpen = () => {
    setForm(nilai_akhir);
    console.log(nilai_akhir);
    setModal(true);
  };

  const handleClose = () => {
    setForm({});
    setErrors({});
    tagForm.current && tagForm.current.reset();
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await patchNilaiAkhir(form.id, form);
      if (res.data && res.data.message && res.data.message === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil menambahkan nilai akhir.");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <SmallButton onClick={handleOpen} type="edit" text="edit"/>

      <LayoutModal modal={modal} title="Edit Nilai Akhir" onClose={handleClose}>
        <form onSubmit={handleSubmit} className="row" ref={tagForm}>
          <div className="col-12">
            <p>{`Gunakan titik " . " sebagai pengganti tanda koma " , "`}</p>
          </div>
          {mhs.jenjang === "D1" && (
            <>
              <div className="col-md-3">
                <FormInput
                  label="Nilai Laporan Tugas Akhir"
                  name="laporan_tugas_akhir"
                  value={form.laporan_tugas_akhir}
                  onChange={handleChange}
                  error={errors.laporan_tugas_akhir}
                />
              </div>
              <div className="col-md-6">
                <FormTextarea
                  label="Judul Laporan Tugas Akhir"
                  name="judul_laporan_tugas_akhir"
                  value={form.judul_laporan_tugas_akhir}
                  onChange={handleChange}
                  error={errors.judul_laporan_tugas_akhir}
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  type="date"
                  label="Tanggal Laporan Tugas Akhir"
                  name="tanggal_laporan_tugas_akhir"
                  value={form.tanggal_laporan_tugas_akhir}
                  onChange={handleChange}
                  error={errors.tanggal_laporan_tugas_akhir}
                />
              </div>
            </>
          )}
          {mhs.jenjang === "D2" && (
            <>
              <div className="col-md-3">
                <FormInput
                  label="Nilai Proyek Khusus"
                  name="proyek_khusus"
                  value={form.proyek_khusus}
                  onChange={handleChange}
                  error={errors.proyek_khusus}
                />
              </div>
              <div className="col-md-6">
                <FormTextarea
                  label="Judul Proyek Khusus"
                  name="judul_proyek_khusus"
                  value={form.judul_proyek_khusus}
                  onChange={handleChange}
                  error={errors.judul_proyek_khusus}
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  type="date"
                  label="Tanggal Proyek Khusus"
                  name="tanggal_proyek_khusus"
                  value={form.tanggal_proyek_khusus}
                  onChange={handleChange}
                  error={errors.tanggal_proyek_khusus}
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  label="Nilai Tugas Akhir"
                  name="tugas_akhir"
                  value={form.tugas_akhir}
                  onChange={handleChange}
                  error={errors.tugas_akhir}
                />
              </div>
              <div className="col-md-6">
                <FormTextarea
                  label="Judul Tugas Akhir"
                  name="judul_tugas_akhir"
                  value={form.judul_tugas_akhir}
                  onChange={handleChange}
                  error={errors.judul_tugas_akhir}
                />
              </div>
              <div className="col-md-3">
                <FormInput
                  type="date"
                  label="Tanggal Tugas Akhir"
                  name="tanggal_tugas_akhir"
                  value={form.tanggal_tugas_akhir}
                  onChange={handleChange}
                  error={errors.tanggal_tugas_akhir}
                />
              </div>
            </>
          )}
          <div className="col-12">
            <FormInput
              label="No Ijazah"
              name="no_ijasah"
              value={form.no_ijasah}
              onChange={handleChange}
              error={errors.no_ijasah}
            />
          </div>
          <div className="col-12 border-top pt-3">
            <div className="btn-group">
              <button className="btn btn-sm btn-success" type="submit">
                Simpan
              </button>
              <button
                className="btn btn-sm btn-secondary"
                type="button"
                onClick={handleClose}
              >
                Tutup
              </button>
            </div>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default NilaiAkhirAkademik;
