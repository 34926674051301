import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
// import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  // LayoutTable,
  // LayoutThead,
  TableNoData,
} from "../../../components/layouts/Table";
import { FilterCabang, LayoutAddBtn } from "../../../components/layouts/TopContent";
import SectionPagination from "../../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../../helpers";
import { userState } from "../../../storage/auth";
import { deleteCeklistMonitoring } from "../apis";
import { FilterJenis } from "../components/Filter";
import { CeklistMonitoringContext } from "../Routes";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../../components/layouts/TableNew";
import SmallButton from "../../../components/forms/SmallButton";

const CeklistMonitoring = ({ mainUrl, onFetchData }) => {
  const {
    ceklistMonitoring,
    setPage,
    setType,
    setFilterCabang,
    cabang,
    departemen,
    auditor,
    isLoaded,
  } = useContext(CeklistMonitoringContext);

  const user = useRecoilValue(userState);

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteCeklistMonitoring(id);
        if (res.data && res.data.msg) {
          onFetchData();
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  useEffect(() => {
    onFetchData();
  }, [onFetchData]);

  if (user.jabatan === "IT Programmer" || user.jabatan === "Penjaminan Mutu") {
    return (
      <>
        <LayoutAddBtn
          add="Tambah Ceklist Monitoring"
          link={`${mainUrl}/create`}
        />

        <div className="row mb-2">
          <div className="col-lg-3">
            <FilterJenis onChange={(res) => setType(res)} />
          </div>
          <div className="col-lg-3">
            <FilterCabang pusat onChange={(res) => setFilterCabang(res)} />
          </div>
        </div>

        <TableNew>
          <Thead>
            <Tr className='text-center'>
              <Th>no</Th>
              <Th>cabang</Th>
              <Th>departemen</Th>
              <Th>auditor</Th>
              <Th>tahun</Th>
              <Th>tahap</Th>
              <Th>kode_dokumen</Th>
              <Th>revisi</Th>
              <Th>tanggal_efektif</Th>
              <Th>bars</Th>
              <Th>Laporan</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ceklistMonitoring.data && ceklistMonitoring.data.length > 0 ? (
              ceklistMonitoring.data.map((cm, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>
                      {cabang.length > 0 &&
                        cabang
                          .filter(
                            (c) => parseInt(c.id) === parseInt(cm.cabang_id)
                          )
                          .map((c) => c.nama)}
                    </Td>
                    <Td className="text-nowrap">
                      {departemen.length > 0 &&
                        departemen
                          .filter(
                            (d) => parseInt(d.id) === parseInt(cm.departemen_id)
                          )
                          .map((c) => c.nama)}
                    </Td>
                    <Td className="text-nowrap">
                      {auditor.length > 0 &&
                        auditor
                          .filter(
                            (a) => parseInt(a.id) === parseInt(cm.auditor_id)
                          )
                          .map((c) => c.nama)}
                    </Td>
                    <Td className="text-nowrap text-center">{cm.tahun}</Td>
                    <Td className="text-nowrap text-center">{cm.tahap}</Td>
                    <Td className='text-nowrap'>{cm.kode_dokumen}</Td>
                    <Td className='text-nowrap'>{cm.revisi}</Td>
                    <Td className='text-nowrap'>{cm.tanggal_efektif}</Td>
                    <Td className="text-nowrap text-center text-nowrap">
                      <SmallButton to={`${mainUrl}/${cm.id}/list`} margin='me-1' type='custom' icon='list' buttonColor='primary-2'/>
                      <SmallButton to={`${mainUrl}/${cm.id}/report`} margin='me-1' type='show'/>
                      <SmallButton to={`${mainUrl}/${cm.id}/edit`} margin='me-1' type='edit'/>
                      <SmallButton type='delete' onClick={() => handleDelete(cm.id)}/>
                    </Td>
                    <Td className='text-nowrap'>
                      <SmallButton to={`${mainUrl}/${cm.id}/laporan`} margin='me-1' type='custom' icon='book-open' buttonColor='dark' text='Laporan'/>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <TableNoData col={11} isLoaded={isLoaded} />
            )}
          </Tbody>
        </TableNew>
        <SectionPagination
          links={ceklistMonitoring.links}
          from={ceklistMonitoring.from}
          to={ceklistMonitoring.to}
          total={ceklistMonitoring.total}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <h1>Ngapain ????</h1>;
  }
};

export default CeklistMonitoring;
