import React, { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchMhs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterAngkatan,
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
  FilterStatus,
} from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { getJenjang, getJurusanShort, getOnOffMhs } from "../../helpers";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import MhsRekapTak from "../mhs/RekapTak";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const NilaiTakAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const defAngkatan = useRecoilValue(angkatanState);

  useEffect(() => setTitle("Nilai TAK"), [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("D1");
  const [jurusan, setJurusan] = useState("");
  const [cabang, setCabang] = useState("");
  const [status, setStatus] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMhs(
        page,
        `search=${search}&angkatan=${angkatan}&pagination=${pagination}&jenjang=${jenjang}&jurusan=${jurusan}&cabang=${cabang}&status=${status}`
      );
      if (res.data && res.data.mhs) setItems(res.data.mhs);
    } catch (err) {
      console.error(err);
    }
  }, [page, pagination, search, angkatan, jenjang, jurusan, cabang, status]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <>
      {/* Filter 1 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterJurusan
            onChange={(res) => {
              setJurusan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterJenjang
            onChange={(res) => {
              setJenjang(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      {/* Filter 2 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterStatus
            onChange={(res) => {
              setStatus(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterAngkatan
            value={angkatan}
            onChange={(res) => {
              setAngkatan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterPagination
            onChange={(res) => {
              setPagination(res);
              setPage(1);
            }}
          />
        </div>
      </div>

      {/* filter 3 */}
      {user.cabang === "pusat" && (
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterCabang
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
        </div>
      )}

      <TextOnOffMhs />

      <div className="row">
        <div className="col-3 pe-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>NIM</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((m, index) => {
                  return (
                    <Tr key={index} className={getOnOffMhs(m)}>
                      <Td className="bg-none text-center">{items.from + index}</Td>
                      <Td className="bg-none text-center">{m.nim}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
        <div className="col-9 ps-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>Nama</Th>
                <Th>Jenjang</Th>
                <Th>Jurusan</Th>
                <Th>Total TAK</Th>
                <Th>
                  <i className="fa fa-fw fa-cog" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((m, index) => {
                  return (
                    <Tr key={index} className={getOnOffMhs(m)}>
                      <Td className="bg-none text-nowrap">{m.nama}</Td>
                      <Td className="bg-none text-nowrap text-center">{getJenjang(m)}</Td>
                      <Td className="bg-none text-nowrap text-center">
                        {getJurusanShort(m.jurusan_id)}
                      </Td>
                      <Td className="bg-none text-nowrap text-center">
                        {m.nilai_akhir ? m.nilai_akhir.tak : "-"}
                      </Td>
                      <Td className="bg-none text-nowrap text-center">
                        <MhsRekapTak mhs={m} />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
      </div>
      {items.links && items.links.length > 0 && (
        <SectionPagination
          from={items.from}
          to={items.to}
          total={items.total}
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

export default NilaiTakAkademik;
