import React, { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchMhs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterAngkatan,
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
  FilterStatus,
} from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { getGrade, getJenjang, getJurusanShort, getOnOffMhs } from "../../helpers";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import NilaiCreateOrUpdateOjt from "./CreateOrUpdateOjt";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const NilaiOjtAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const defAngkatan = useRecoilValue(angkatanState);

  useEffect(() => setTitle("Nilai OJT"), [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("D1");
  const [jurusan, setJurusan] = useState("");
  const [cabang, setCabang] = useState("");
  const [status, setStatus] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMhs(
        page,
        `with_nilai_ojt=true&search=${search}&angkatan=${angkatan}&pagination=${pagination}&jenjang=${jenjang}&jurusan=${jurusan}&cabang=${cabang}&status=${status}`
      );
      if (res.data && res.data.mhs) setItems(res.data.mhs);
    } catch (err) {
      console.error(err);
    }
  }, [page, pagination, search, angkatan, jenjang, jurusan, cabang, status]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <>
      {/* Filter 1 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterJurusan
            onChange={(res) => {
              setJurusan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterJenjang
            onChange={(res) => {
              setJenjang(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      {/* Filter 2 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterStatus
            onChange={(res) => {
              setStatus(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterAngkatan
            value={angkatan}
            onChange={(res) => {
              setAngkatan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterPagination
            onChange={(res) => {
              setPagination(res);
              setPage(1);
            }}
          />
        </div>
      </div>

      {/* filter 3 */}
      {user.cabang === "pusat" && (
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterCabang
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
        </div>
      )}

      <TextOnOffMhs />

      <div className="row">
        <div className="col-2 pe-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>NIM</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((m, index) => {
                  return (
                    <Tr key={index} className={getOnOffMhs(m)}>
                      <Td className="bg-none text-center">{items.from + index}</Td>
                      <Td className="bg-none text-center">{m.nim}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
        <div className="col-8 px-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th className="text-nowrap">Nama</Th>
                <Th className="text-nowrap">Jenjang</Th>
                <Th className="text-nowrap">Jurusan</Th>
                <Th className="text-nowrap">On The Job Training 1</Th>
                <Th className="text-nowrap">Grade 1</Th>
                <Th className="text-nowrap">Angka Mutu 1</Th>
                {jenjang === "D2" && (
                  <>
                    <Th className="text-nowrap">On The Job Training 2</Th>
                    <Th className="text-nowrap">Grade 2</Th>
                    <Th className="text-nowrap">Angka Mutu 2</Th>
                  </>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((m, index) => {
                  return (
                    <Tr key={index} className={getOnOffMhs(m)}>
                      <Td className="bg-none text-nowrap">{m.nama}</Td>
                      <Td className="bg-none text-nowrap text-center">{getJenjang(m)}</Td>
                      <Td className="bg-none text-nowrap text-center">
                        {getJurusanShort(m.jurusan_id)}
                      </Td>
                      <NilaiOjt1 mhs={m} jenjang={jenjang} />
                      <NilaiOjt2 mhs={m} jenjang={jenjang} />
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
        <div className="col-2 ps-0">
          <TableNew copy={false}>
            <Thead>
              <Tr className="text-center">
                <Th>
                  <i className="fa fa-fw fa-cog" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((m, index) => {
                  return (
                    <Tr key={index} className={getOnOffMhs(m)}>
                      <Td className="bg-none text-nowrap text-center">
                        <NilaiCreateOrUpdateOjt
                          mhs={m}
                          onFetchData={() => fetchData()}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
      </div>
      {items.links && items.links.length > 0 && (
        <SectionPagination
          from={items.from}
          to={items.to}
          total={items.total}
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

const NilaiOjt1 = ({ mhs, jenjang }) => {
  return (
    <>
      <Td className="bg-none bg-primary-4 text-info text-center">
        {mhs.nilai_ojt &&
          mhs.nilai_ojt.length > 0 &&
          mhs.nilai_ojt
            .filter((n) => parseInt(n.ojt_ke) === 1)
            .map((n, index) => {
              return n.nilai;
            })}
      </Td>
      <Td className="bg-none bg-primary-4 text-info text-center">
        {mhs.nilai_ojt &&
          mhs.nilai_ojt.length > 0 &&
          mhs.nilai_ojt
            .filter((n) => parseInt(n.ojt_ke) === 1)
            .map((n, index) => {
              return getGrade(parseInt(n.grade));
            })}
      </Td>
      <Td className="bg-none bg-primary-4 text-info text-center">
        {mhs.nilai_ojt &&
          mhs.nilai_ojt.length > 0 &&
          mhs.nilai_ojt
            .filter((n) => parseInt(n.ojt_ke) === 1)
            .map((n, index) => {
              return n.angka_mutu;
            })}
      </Td>
    </>
  );
};

const NilaiOjt2 = ({ mhs, jenjang }) => {
  return (
    <>
      {jenjang === "D2" && (
        <>
          <Td className="bg-none bg-primary-4 text-warning text-center">
            {mhs.nilai_ojt &&
              mhs.nilai_ojt.length > 0 &&
              mhs.nilai_ojt
                .filter((n) => parseInt(n.ojt_ke) === 2)
                .map((n, index) => {
                  return n.nilai;
                })}
          </Td>
          <Td className="bg-none bg-primary-4 text-warning text-center">
            {mhs.nilai_ojt &&
              mhs.nilai_ojt.length > 0 &&
              mhs.nilai_ojt
                .filter((n) => parseInt(n.ojt_ke) === 2)
                .map((n, index) => {
                  return getGrade(parseInt(n.grade));
                })}
          </Td>
          <Td className="bg-none bg-primary-4 text-warning text-center">
            {mhs.nilai_ojt &&
              mhs.nilai_ojt.length > 0 &&
              mhs.nilai_ojt
                .filter((n) => parseInt(n.ojt_ke) === 2)
                .map((n, index) => {
                  return n.angka_mutu;
                })}
          </Td>
        </>
      )}
    </>
  );
};

export default NilaiOjtAkademik;
