import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { fetchBidang, fetchProgkam, findMhs } from "../../../api";
import PrintBtnPrint from "../../../components/print/BtnPrint";
import PrintTtd from "../../../components/print/Ttd";
import { kacabState, userState } from "../../../storage/auth";

const NilaiPrintTranskripTak = () => {
  const user = useRecoilValue(userState);
  const { mhs_id } = useParams();
  const kacab = useRecoilValue(kacabState);
  let nomor = 1;

  // this handle if kegiatan is null
  const [dataToHide, setDataToHide] = useState([]);
  // this handle if kegiatan is null

  const [mhs, setMhs] = useState({});
  const [bidang, setBidang] = useState([]);
  const [progkam, setProgkam] = useState([]);
  const [nilaiAkhir, setNilaiAkhir] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await findMhs(mhs_id, `with_krs=1&with_nilai_ojt=1`);
        if (res.data && res.data.mhs) {
          setMhs(res.data.mhs);
          setNilaiAkhir(res.data.mhs.nilai_akhir);
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (!mhs.nim) {
      fetchData();
    }

    const fetchDataBidang = async () => {
      try {
        const res = await fetchBidang("all");
        setBidang(res.data);
      } catch (err) {
        console.error(err.response);
      }
    };

    if (!mhs.nim) {
      fetchDataBidang();
    }

    const fetchDataProgkam = async () => {
      try {
        const res = await fetchProgkam(
          "all",
          `transkrip=1&nim_mhs=${mhs.nim}&mhs_id=${mhs_id}`
        );
        setProgkam(res.data);
      } catch (err) {
        console.error(err.response);
      }
    };

    if (mhs.nim) {
      fetchDataProgkam();
    }
  }, [mhs_id, mhs.nim]);

  const getNilaiByBidangKriteria = (bidang_id, kriteria_id) => {
    if (progkam.length > 0 && bidang_id && kriteria_id) {
      let mhs_lep =
        progkam.length > 0 &&
        progkam
          .filter(
            (p) =>
              p.nilai &&
              p.bidang &&
              parseInt(p.bidang.id) === parseInt(bidang_id) &&
              parseInt(p.bidang.kriteria) === parseInt(kriteria_id)
          )
          .map((p) => parseInt(p.nilai));

      let total_mhs_lep =
        mhs_lep.length > 0 && mhs_lep.reduce((prev, curr) => prev + curr);

      let mhs_progkam =
        progkam.length > 0 &&
        progkam
          .filter(
            (p) =>
              !p.nilai &&
              p.bidang &&
              parseInt(p.bidang.id) === parseInt(bidang_id) &&
              parseInt(p.bidang.kriteria) === parseInt(kriteria_id)
          )
          .filter((p) => p.mhs && p.mhs[0] && p.mhs[0].pivot.nilai)
          .map((p) => parseInt(p.mhs[0].pivot.nilai));

      let total_mhs_progkam =
        mhs_progkam.length > 0 &&
        mhs_progkam.reduce((prev, curr) => prev + curr);

      let total_all = 0;

      total_all += total_mhs_lep + total_mhs_progkam;

      if (parseInt(total_all) === 0) {
        setDataToHide((prevState) => {
          return [...prevState, bidang_id];
        });
      }

      return total_all || "";
    }
  };

  const getTotalNilai = () => {
    if (progkam.length > 0) {
      let mhs_lep =
        progkam.length > 0 &&
        progkam.filter((p) => p.nilai).map((p) => parseInt(p.nilai));

      let total_mhs_lep =
        mhs_lep.length > 0 && mhs_lep.reduce((prev, curr) => prev + curr);

      let mhs_progkam =
        progkam.length > 0 &&
        progkam
          .filter((p) => !p.nilai)
          .filter((p) => p.mhs && p.mhs[0] && p.mhs[0].pivot.nilai)
          .map((p) => parseInt(p.mhs[0].pivot.nilai));

      let total_mhs_progkam =
        mhs_progkam.length > 0 &&
        mhs_progkam.reduce((prev, curr) => prev + curr);

      let total_all = 0;
      total_all += total_mhs_lep + total_mhs_progkam;

      return total_all || "";
    }
  };

  const getPredikat = (nilai) => {
    if (nilai && mhs.jenjang) {
      if (mhs.jenjang === "D1") {
        if (nilai > 110) {
          return "Sangat Baik";
        }
        if (nilai >= 91 && nilai <= 110) {
          return "Baik";
        }
        if (nilai >= 80 && nilai <= 90) {
          return "Cukup";
        }
      }

      if (mhs.jenjang === "D2") {
        if (nilai > 150) {
          return "Sangat Baik";
        }
        if (nilai >= 131 && nilai <= 150) {
          return "Baik";
        }
        if (nilai >= 120 && nilai <= 130) {
          return "Cukup";
        }
      }

      return "Kurang";
    }
  };

  if (mhs.id && bidang.length > 0 && progkam.length > 0) {
    return (
      <>
        <Helmet>
          <body className="bg-white" />
          <style type="text/css">
            {`
                @media print{
                  @page {
                    size: portrait;
                    margin: 1mm 18mm 1mm 18mm;
                  }
                }
                * {
                  font-family: 'Cambria', sans-serif;
                  color: #000000;
                }
                table {
                  border: 1px solid blue;
                  width: 100%;
                }
                table > thead > tr > th{
                  border:1px solid black;
                }
                table > tbody > tr > td{
                  border:1px solid black;
                }
                table > tfoot > tr > th{
                  border:1px solid black;
                }
                .dont-show {
                  padding-top: 5px;
                  border-right: 1px solid white;
                  border-left: 1px solid white;
                }
              `}
          </style>
        </Helmet>

        <div className="container-fluid">
          <div className="py-5"></div>
          <br />
          <br />
          <br />
          <h3 className="text-center mt-5 pt-5 font-weight-bold mb-3">
            TRANSKRIP AKTIVITAS KEMAHASISWAAN (TAK) <br />
            (PENGEMBANGAN SOFTSKILL)
          </h3>

          <h5 className="mb-2 font-weight-bold">
            <div className="row mb-2">
              <div className="col-2">Nama</div>
              <div className="col-10">: {mhs.nama}</div>
            </div>
            <div className="row mb-2">
              <div className="col-2">Nomor Induk</div>
              <div className="col-10">: {mhs.nim}</div>
            </div>
            <div className="row mb-2">
              <div className="col-2">Program</div>
              <div className="col-10">
                : {mhs.jenjang && mhs.jenjang.split("D").join("")} Tahun -{" "}
                {mhs.jurusan_ && mhs.jurusan_.nama}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-2">Tahun Angkatan</div>
              <div className="col-10">
                : {mhs.angkatan} -{" "}
                {/* {mhs.jenjang === "D2" &&
                  nilaiAkhir.tanggal_tugas_akhir &&
                  nilaiAkhir.tanggal_tugas_akhir.split("-")[0]}
                {mhs.jenjang === "D1" &&
                  nilaiAkhir.tanggal_laporan_tugas_akhir &&
                  nilaiAkhir.tanggal_laporan_tugas_akhir.split("-")[0]} */}
                {parseInt(mhs.angkatan) + 1}
              </div>
            </div>
          </h5>

          <p className="mb-0 pb-0 text-justify">
            Transkrip Aktivitas Kemahasiswaan (TAK) adalah ukuran penguasaan
            keterampilan sosial (soft skill) yang dimiliki oleh mahasiswa yang
            diukur berdasarkan berbagai kegiatan ekstra/intra/ko-kurikuler yang
            diikuti selama perkuliahan.
          </p>

          <table className="my-2">
            <thead
              className="text-center"
              style={{ textTransform: "uppercase" }}
            >
              <tr>
                <th className="align-middle" colSpan={2}>
                  Kriteria
                </th>
                <th>Point *</th>
              </tr>
            </thead>
            <tbody>
              {/* Kegiatan Pilihan */}

              <tr>
                <td colSpan={2} className="font-weight-bold">
                  Kegiatan Pilihan
                </td>
                <td></td>
              </tr>

              {bidang.length > 0 &&
                bidang
                  .filter(
                    (b) =>
                      parseInt(b.kriteria) === 2 &&
                      dataToHide.filter(
                        (dth) => parseInt(dth) === parseInt(b.id)
                      ).length === 0
                  )
                  .map((b, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">{nomor++}</td>
                        <td>{b.nama}</td>
                        <td className="text-center">
                          {getNilaiByBidangKriteria(parseInt(b.id), 2)}
                        </td>
                      </tr>
                    );
                  })}

              {/* Kegiatan Wajib */}

              <tr>
                <td colSpan={2} className="font-weight-bold">
                  Kegiatan Wajib
                </td>
                <td></td>
              </tr>

              {bidang.length > 0 &&
                bidang
                  .filter(
                    (b) =>
                      parseInt(b.kriteria) === 1 &&
                      dataToHide.filter(
                        (dth) => parseInt(dth) === parseInt(b.id)
                      ).length === 0
                  )
                  .map((b, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">{nomor++}</td>
                        <td>{b.nama}</td>
                        <td className="text-center">
                          {getNilaiByBidangKriteria(parseInt(b.id), 1)}
                        </td>
                      </tr>
                    );
                  })}

              <tr>
                <td colSpan={2}>Jumlah Point</td>
                <td className="text-center">{getTotalNilai()}</td>
              </tr>

              <tr>
                <td colSpan={2}>Predikat</td>
                <td className="text-center">
                  {getPredikat(parseInt(getTotalNilai()))}
                  {}
                </td>
              </tr>
            </tbody>
          </table>
          <p className="font-weight-bold mb-0 pb-2 text-justify">
            Judul Tugas Akhir :{" "}
            {nilaiAkhir.judul_tugas_akhir
              ? nilaiAkhir.judul_tugas_akhir
              : nilaiAkhir.judul_laporan_tugas_akhir}
          </p>

          <div className="row mb-5 pb-3">
            <div className="col-6">
              <b>Predikat TAK Program 1 Tahun</b>
              <div className="row">
                <div className="col-4">{`> 110`}</div>
                <div className="col-8">: Sangat Baik</div>
              </div>
              <div className="row">
                <div className="col-4">{`91 - 110`}</div>
                <div className="col-8">: Baik</div>
              </div>
              <div className="row">
                <div className="col-4">{`80 - 90`}</div>
                <div className="col-8">: Cukup</div>
              </div>
            </div>
            <div className="col-6">
              <b>Predikat TAK Program 2 Tahun</b>
              <div className="row">
                <div className="col-4">{`> 150`}</div>
                <div className="col-8">: Sangat Baik</div>
              </div>
              <div className="row">
                <div className="col-4">{`131 - 150`}</div>
                <div className="col-8">: Baik</div>
              </div>
              <div className="row">
                <div className="col-4">{`120 - 130`}</div>
                <div className="col-8">: Cukup</div>
              </div>
            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col-6">
              <PrintTtd
                nama={kacab.nama}
                jabatan="Kepala Cabang"
                footer={`NIP : ${kacab.nip}`}
                cabang={kacab.cabang}
                tanggal="default"
              />
            </div>
          </div>
        </div>

        <PrintBtnPrint />

        <div className="container bg-silver text-black rounded-3 p-4 mb-5 d-print-none">
          <p className="fs-5">Berikut merupakan settingan untuk cetak transkrip TAK :</p>
          <ul className="fs-6">
            <li>Paper Size : A4</li>
            <li>Margin : none</li>
            <li>Scale : default</li>
          </ul>
          <p className="mb-0">(Kalimat ini tidak akan terlihat saat di print.)</p>
        </div>
      </>
    );
  } else {
    return "";
  }
};

export default NilaiPrintTranskripTak;
